import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonPage,
    IonRow,
    IonTextarea,
    IonTitle,
    IonToolbar,
    useIonModal,
    useIonViewDidEnter
} from "@ionic/react";
import React, {useEffect, useMemo, useRef, useState} from "react";
import RapportVisiteTechnique from "./RapportVisiteTechnique";
import {
    cameraSharp,
    documentText,
    informationCircleOutline,
    informationCircleSharp,
    navigateOutline,
    navigateSharp,
    pauseSharp,
    playSharp,
    searchOutline,
    warningOutline,
    warningSharp
} from "ionicons/icons";
import {useParams} from "react-router";
import InterventionLabel from "../components/Shared/InterventionLabel";
import CompteRenduSavAndEntretient from "../components/SavAndEntretient/CompteRenduSavAndEntretient";
import {EtatLieux} from "../components/Pose/EtatLieux";
import CompteRenduInstallation from "../components/Pose/CompteRenduInstallation";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getIntervention} from "../calls/Interventions/getIntervention";
import {AllInterventionStatus, Intervention, InterventionType, ProductTypology} from "../models/intervention.model";
import {useProposalDetail} from "../components/AffaireDetail/useProposalDetail";
import {DateTime} from "luxon";
import {usePhotoGallery} from "../hooks/usePhotoGallery";
import {FileToUploadDto, interventionAttachFiles} from "../calls/Interventions/attachInterventionFiles";
import {useActiveCompany} from "../components/Company/company.store";
import {getInterventionFiles} from "../calls/Interventions/getInterventionFiles";
import {
    useAllInterventionProductTypologies
} from "../components/RapportVisiteTechnique/useAllInterventionProductTypologies";
import {cancelVisiteTechnique} from "../calls/Interventions/cancelVisitetechnique";
import Cadastre from "../components/RapportVisiteTechnique/Cadastre";
import styled from "@emotion/styled";
import {saveAs} from "file-saver";
import * as blobUtil from 'blob-util';
import {sum} from 'lodash';
import FilerobotImageEditor, {TABS, TOOLS,} from 'react-filerobot-image-editor';
import {CameraSource} from "@capacitor/camera";
import useLongPress from "../hooks/useLongPress";
import {useHistory} from "react-router-dom";

const InterventionDetail: React.FC = () => {
    useIonViewDidEnter(() => {
        window.dispatchEvent(new Event("resize"));
    });
    const { id } = useParams<{ id: string }>();

    const { isError, isFetched, isLoading, data: intervention } = useInterventionDetail(id);

    if (isLoading) {
        return <></>;
    }

    if (isError) {
        return <></>;
    }

    return (
        <InterventionPage intervention={intervention} />
    )
};

export default InterventionDetail;

function InterventionPage({ intervention }: { intervention: Intervention }) {
    const { id, customer, proposal, reference, place: { address, zipCode, town, coords }, type, typeData } = intervention;

    const addressText = [
        address,
        zipCode,
        town,
    ].filter(i => !!i).join(', ');

    const { isLoading, isError, typologies } = useAllInterventionProductTypologies(proposal!.id as string);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>
                        <InterventionLabel type={type} />
                    </IonTitle>
                    <PoseButtons type={type} />
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <AddressItem intervention={intervention} />

                {
                    type === InterventionType.POSE && (
                        <>
                            <IonGrid className= "ion-no-padding">
                                <IonRow>
                                    <IonCol>
                                        <IonItem>
                                            <IonLabel>
                                                <h3>Début Pose</h3>
                                                <p>{formatPoseDate(typeData.theoricalStartDate)}</p>
                                            </IonLabel>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol>
                                        <IonItem>
                                            <IonLabel>
                                                <h3>Fin Pose</h3>
                                                <p>{formatPoseDate(typeData.theoricalEndDate)}</p>
                                            </IonLabel>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>

                            <IonItem button detail={true} className={'ion-text-wrap'}
                                     routerLink={`/app/InstallationProcedures?selectedTypology=${typologies[0] || ''}`}
                                     routerDirection={'forward'}
                            >
                                <IonIcon slot={'start'} md={informationCircleSharp} ios={informationCircleOutline} />
                                <IonLabel>
                                    <h3>Procédure installation</h3>
                                    <p>Consulter la liste des procédures d'installation</p>
                                </IonLabel>
                            </IonItem>
                        </>
                    )
                }

                {
                    type !== InterventionType.POSE && (
                        <IonItem>
                            <IonLabel>
                                <h3>Date <InterventionLabel type={type} /></h3>
                                <p>{intervention.date ? formatDate(intervention.date) : 'Non planifié'}</p>
                            </IonLabel>
                        </IonItem>
                    )
                }

                <ProductList proposalId={proposal.id as string} />

                <InterventionPhotos id={id} reference={reference} customerName={customer.name} />

                <InterventionCadastre
                    id={id}
                    reference={reference}
                    customerName={customer.name}
                    proposalId={proposal.id as string}
                    chantier={{ address, zipCode, town, coords } as any}
                />

                <InterventionPdfDocuments
                    id={id}
                />
            </IonContent>

            <IonFooter>
                <IonToolbar>
                    <InterventionButton id={id} type={type} proposalId={proposal.id as string} />
                </IonToolbar>
            </IonFooter>
        </IonPage>
    )
}

function AddressItem({ intervention }: { intervention: Intervention }) {
    const { id, customer, proposal, reference, place: { address, zipCode, town, coords }, type, typeData } = intervention;

    const addressText = [
        address,
        zipCode,
        town,
    ].filter(i => !!i).join(', ');

    const url = useMemo(() => {
        const baseUrl = 'https://www.google.com/maps/dir/?api=1&destination=$destination&travelmode=driving';

        const [lat, lng] = coords || [0, 0];

        return baseUrl.replace('$destination', [lng, lat].join(','));
    }, [intervention.id]);

    return (
        <IonItem routerLink={url} target={"_blank"}>
            <IonLabel className="ion-text-wrap">
                <h3>{customer.name}</h3>
                <p>{addressText}</p>
            </IonLabel>
            <IonIcon slot={"end"} ios={navigateOutline} md={navigateSharp} />
        </IonItem>
    )
}

const formatDate = (date: string) => DateTime.fromISO(date, { zone: "Europe/Paris" }).setLocale('fr').toFormat('dd/MM/yyyy HH:mm');
const formatPoseDate = (date: string) => DateTime.fromISO(date, { zone: "Europe/Paris" }).setLocale('fr').toLocaleString(DateTime.DATE_MED);

function ProductList({ proposalId }: { proposalId: string }) {
    const { isLoading, data: proposal } = useProposalDetail(proposalId);

    if (isLoading) {
        return (
            <></>
        )
    }

    const products = (proposal?.lines || []).filter(p => p.fk_product_type === '0'); // On ne filtre que les produits dans les lignes du proposal

    return (
        <IonList>
            {
                products.map(
                    (product, index) => (
                        <IonItem key={product.id}>
                            <IonLabel className="ion-text-wrap">
                                <h3>{product.product_label}</h3>
                                <p>Produit {products.length === 1 ? '' : `${(index+1)} / ${products.length}`}</p>
                            </IonLabel>
                        </IonItem>
                    )
                )
            }
        </IonList>
    )
}

interface IonItemLongPressAwareProps {
    onClick(): void,
    onLongPress(): void
}

function IonItemLongPressAware({ onClick, onLongPress }: IonItemLongPressAwareProps) {
    const { action, handlers } = useLongPress()

    useEffect(() => {
        if (action === 'click') {
            onClick();
        }

        if (action === 'longpress') {
            onLongPress();
        }
    }, [action]);

    return (
        <IonItem {...handlers} button detail={true}>
            <IonIcon slot={'start'} icon={cameraSharp} />
            <IonLabel>
                <h3>Prendre ou attacher des photos</h3>
                <p>Ajouter photos</p>
            </IonLabel>
        </IonItem>
    )
}

function InterventionPhotos({ id, reference, customerName }: { id: string, reference: string, customerName: string }) {
    const company = useActiveCompany();

    const { mutateAsync: attachFiles, isLoading: uploading } = useMutation((dto: FileToUploadDto[]) => interventionAttachFiles(id, dto));
     const { data: files, refetch, isLoading } = useQuery(['intervention', id, 'files'], () => getInterventionFiles(id));

    const images = (files || []).filter(f => f.contentType.startsWith('image/'));

    const { photos, takePhoto } = usePhotoGallery({
        async onCaptured(photos) {
            const dtos: FileToUploadDto[] = photos.map((p, index) => {
                const no = images.length + (index + 1);
                const base64Content = p.base64Content.split(',')[1];

                return {
                    base64Content,
                    filename: p.filename,
                    tag: 'Photos VT',
                    description: `Photos VT ${company.name} ${customerName} ${reference} - ${no}`,
                    contentType: p.contentType,
                }
            });

            await attachFiles(dtos);

            refetch();
        }
    });

    return (
        <>
            <IonItemLongPressAware
                onClick={() => takePhoto()}
                onLongPress={() => takePhoto({ source: CameraSource.Photos })}
            />

            <IonGrid>
                <IonRow>
                    {
                        images.map((image, index) => {
                            const src = `data:${image.contentType};base64,${image.base64Content}`;

                            return (
                                <ImageCol
                                    key={`image-${index}`}
                                    interventionId={id}
                                    source={src}
                                    savedImageDescription={image.description}
                                    defaultSavedImageName={`annoted-${image.filename}`}
                                    onClose={
                                        () => {
                                            refetch(); // Reftech de la liste des images
                                        }
                                    }
                                />
                            )
                        })
                    }
                </IonRow>
            </IonGrid>
        </>
    )
}

interface ImageColProps {
    interventionId: string;
    source: string;
    savedImageDescription: string;
    defaultSavedImageName?: string,
    onClose: () => void
}

function ImageCol({ interventionId, source, savedImageDescription, defaultSavedImageName, onClose }: ImageColProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <IonCol size="3" onClick={() => setIsOpen(true)}>
                <IonImg src={source} />
            </IonCol>

            <ImageEditor
                interventionId={interventionId}
                isOpen={isOpen}
                source={source}
                savedImageDescription={savedImageDescription}
                defaultSavedImageName={defaultSavedImageName}
                onClose={() => {
                    setIsOpen(false);
                    onClose();
                }}
            />
        </>
    )
}

interface ImageEditorProps {
    interventionId: string;
    isOpen: boolean;
    source: string;
    savedImageDescription: string,
    defaultSavedImageName?: string,
    onClose: () => void
}

function ImageEditor({ interventionId, isOpen, source, savedImageDescription, defaultSavedImageName, onClose }: ImageEditorProps) {
    const company = useActiveCompany();

    const { mutateAsync: attachFiles, isLoading: uploading } = useMutation((dto: FileToUploadDto[]) => interventionAttachFiles(interventionId, dto));

    return (
        <IonModal isOpen={isOpen} className={'fullscreen'}>
            <FilerobotImageEditor
                source={source}
                onBeforeSave={() => false}
                onSave={
                    async (image, designState) => {
                        const base64Content = (image.imageBase64 || '').split(',')[1];
                        const defaultFilename = `${interventionId}-${company.name}.${image.extension}`;

                        const dto: FileToUploadDto = {
                            base64Content,
                            filename: image?.fullName || defaultFilename,
                            tag: '[Annotation] Photos VT',
                            description: `[Annotée] ${savedImageDescription}`,
                            contentType: image.mimeType,
                        }

                        await attachFiles([dto]);

                        onClose();
                    }
                }
                onClose={onClose}
                defaultSavedImageName={defaultSavedImageName}
                annotationsCommon={{
                    fill: '#ff0000',
                    stroke: '#ff0000',
                }}
                Text={{ text: 'Commentaires' }}
                Rotate={{ angle: 90, componentType: 'slider' }}
                language={'fr'}
                translations={{
                    save: 'Enregistrer',
                    saveAs: 'Enregistrer sous',
                    back: 'Retour',
                    loading: 'Chargement...',
                    adjustTab: 'Ajuster',
                    annotateTab: 'Annoter',

                    // arrowTool: 'Flèche',
                    // addImage: '+ Ajouter image',
                    // lineTool: 'Ligne',
                    penTool: 'Dessiner',
                    // polygonTool: 'Polygone',
                    // rotateTool: 'Rotation',
                    // textTool: 'Texte',

                    // original: 'Originale',
                    // custom: 'Personnalisé',
                    // square: 'Carré',
                    // landscape: 'Paysage',
                    // portrait: 'Portrait',
                }}
                Crop={{
                    presetsItems: [
                        {
                            titleKey: 'classicTv',
                            descriptionKey: '4:3',
                            ratio: '4:3',
                            // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
                        },
                        {
                            titleKey: 'cinemascope',
                            descriptionKey: '21:9',
                            ratio: '21:9',
                            // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
                        },
                    ],
                }}
                tabsIds={[TABS.ADJUST, TABS.ANNOTATE]} // or {['Adjust', 'Annotate', 'Watermark']}
                defaultTabId={TABS.ANNOTATE} // or 'Annotate'
                defaultToolId={TOOLS.TEXT} // or 'Text'
                previewPixelRatio={1}
                savingPixelRatio={1}
            />
        </IonModal>
    )
}

const circleDownIcon = '/assets/icon/circle-down.svg';

interface InterventionCadastreProps {
    id: string,
    reference: string,
    customerName: string,
    proposalId: string,
    chantier: {
        address: string,
        zipCode: string,
        town: string,
        coords: number[],
    }
}

function InterventionCadastre({ id, reference, customerName, proposalId, chantier }: InterventionCadastreProps) {
    const { isLoading, data: proposal } = useProposalDetail(proposalId);
    // On ne filtre que les produits Photo dans les lignes du proposal
    const products = (proposal?.lines || []).filter(p => p.fk_product_type === '0' && p.array_options?.options_product_typology === ProductTypology.PHOTOVOLTAIC);
    const qtyPanneaux = sum(products.map(p => Number(p.qty))) || 0;

    const {refetch} = useQuery(['intervention', id, 'files'], () => getInterventionFiles(id));

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <IonItem button detail={true} onClick={() => setIsOpen(true)}>
                <IonIcon slot={'start'} icon={searchOutline} />
                <IonLabel>
                    <h3>Recherche parcelle cadastrale</h3>
                    <p>Cadastre</p>
                </IonLabel>
            </IonItem>

            <LgModal isOpen={isOpen}>
                <Cadastre
                    interventionId={id}
                    qtyPanneaux={qtyPanneaux}
                    chantier={chantier}
                    reference={reference}
                    customerName={customerName}
                    onDismiss={
                        (data: any, role: string) => {
                            if (role === 'confirm') {
                                refetch();
                            }

                            setIsOpen(false);
                        }
                    }
                />
            </LgModal>
        </>
    )
}

function InterventionPdfDocuments({ id }: { id: string }) {
    const {data: files = []} = useQuery(['intervention', id, 'files'], () => getInterventionFiles(id));

    const documents = files.filter(f => f.contentType === 'application/pdf');

    const download = async (base64: string, filename: string) => {
        const blob = await blobUtil.base64StringToBlob(base64);
        saveAs(blob, filename);
    }

    return (
        <IonList>
            <IonListHeader>
                <IonLabel>Fichiers</IonLabel>
            </IonListHeader>

            {
                documents.map(
                    doc => (
                        <IonItem key={doc.filename} button onClick={() => download(doc.base64Content, doc.filename)}>
                            <IonIcon slot={'start'} icon={documentText} />
                            <IonLabel>
                                <h3>{doc.filename}</h3>
                                <p>{doc.description}</p>
                            </IonLabel>
                            <IonIcon icon={circleDownIcon} slot="end"></IonIcon>
                        </IonItem>
                    )
                )
            }
        </IonList>
    );
}

const LgModal = styled(IonModal)`
    --width: 80%;
    --height: 80%;
`;

function useInterventionDetail(id: string) {
    return useQuery(['intervention', id], ({ queryKey }) => {
        return getIntervention(queryKey[1] as string);
    });
}

function InterventionButton({ id, type, proposalId  }: { id: string, type: string, proposalId: string  }) {
    return (
        <>
            {{
                VISITE_TECHNIQUE: <VisiteTechniqueButtons id={id} proposalId={proposalId} />,
                POSE: <StartPoseButton />,
                SAV: <StartSavOrEntretientButton type={'SAV'} />,
                ENTRETIENT: <StartSavOrEntretientButton type={'ENTRETIENT'} />
            }[type]}
        </>
    )
}

function VisiteTechniqueButtons({ id, proposalId }: { id: string, proposalId: string }) {
    const { data: intervention } = useInterventionDetail(id);

    if (intervention?.status !== AllInterventionStatus.SCHEDULED) {
        return <></>;
    }

    return (
        <IonRow>
            <IonCol>
                <CancelButton id={id} />
            </IonCol>
            <IonCol>
                <StartVTButton id={id} proposalId={proposalId} />
            </IonCol>
        </IonRow>
    )
}

function StartVTButton({ id, proposalId }: { id: string, proposalId: string }) {
    const { isLoading, isError, typologies } = useAllInterventionProductTypologies(proposalId);

    // Access the client
    const queryClient = useQueryClient();

    const history = useHistory();

    const [present, dismiss] = useIonModal(RapportVisiteTechnique, {
        interventionId: id,
        proposalId,
        typologies,
        async onDismiss() {
            // Invalidate and refetch
            await queryClient.invalidateQueries({ queryKey: ['intervention', id, 'files'] });

            dismiss();

            history.push("/app/Interventions");
        }
    });

    const disabled = isLoading || isError;

    return (
        <IonButton expand={"block"} onClick={() => present()} disabled={disabled}>
            Démarrer VT
        </IonButton>
    )
}

function CancelButton({ id }: { id: string }) {
    const modal = useRef<HTMLIonModalElement>(null);

    const { mutateAsync, isLoading } = useMutation((cancellationReason: string) => cancelVisiteTechnique(id, cancellationReason));

    const [reason, setReason] = useState<string | null | undefined>('Impossibilité technique');
    const isInvalid = (!reason || reason.length < 3);

    async function confirm() {
        try {
            await mutateAsync(reason as string);
            modal.current?.dismiss(reason, 'confirm');
        } catch (e) {
            console.error(e);
        }
    }

    function dismiss() {
        modal.current?.dismiss();
    }

    return (
        <>
            <IonButton id="open-cancel-modal" expand="block" fill={'outline'} color={'danger'}>
                <IonIcon slot={'start'} md={warningSharp} ios={warningOutline}></IonIcon>
                Annuler VT
            </IonButton>

            <IonModal ref={modal} trigger="open-cancel-modal">
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={dismiss}>Fermer</IonButton>
                        </IonButtons>
                        <IonTitle>Annulation VT</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <p className={'ion-text-center'}>La visite technique sera annulée après confirmation</p>
                    <IonItem>
                        <IonLabel position="stacked">Raison de l'annulation</IonLabel>
                        <IonTextarea value={reason} rows={5} onIonChange={e => setReason(e.detail.value)} placeholder="Raison de l'annulation" />
                    </IonItem>
                </IonContent>

                <IonFooter>
                    <IonButton expand={'block'} color={'danger'} onClick={confirm} disabled={isInvalid}>
                        Confirmer annulation
                    </IonButton>
                </IonFooter>
            </IonModal>
        </>
    )
}

const poseStatusLabelMap: any = {
    idle: 'Démarrer',
    started: 'Clôturer',
}

const poseStatusMap: any = {
    idle: 'started',
    started: 'finalized',
}

function StartPoseButton() {
    const [status, setStatus] = useState<string>('idle');

    const [presentEtatLieux, dismissEtatLieux] = useIonModal(EtatLieux, {
        onDismiss() {
            dismissEtatLieux();
        }
    });

    const [presentCr, dismissCr] = useIonModal(CompteRenduInstallation, {
        onDismiss() {
            dismissCr();
        }
    });


    const label = poseStatusLabelMap[status];

    const handleStatus = () => {
        const st = poseStatusMap[status];
        setStatus(st);

        if (st === 'started') {
            presentEtatLieux();
        }

        if (st === 'finalized') {
            presentCr();
        }
    }

    return (
        label && (
            <IonButton expand={"block"} onClick={() => handleStatus()}>
                {label}
            </IonButton>
        )
    )
}

function PoseButtons({ type }: { type: string }) {
    const [pause, setPause] = useState<boolean>(false);

    if (type !== 'POSE') {
        return <></>;
    }

    return (
        <IonButtons slot="end">
            <IonButton onClick={() => setPause(prev => !prev)}>
                <IonIcon slot="start" icon={!pause ? pauseSharp : playSharp}></IonIcon>
                {!pause ? 'Pause' : 'Reprendre'}
            </IonButton>
        </IonButtons>
    )
}

const statusLabelMap: any = {
    idle: 'Arrivé',
    arrived: 'Remplir Compte rendu',
    compteRendu: 'Départ',
}

const statusMap: any = {
    idle: 'arrived',
    arrived: 'compteRendu',
    compteRendu: 'departure',
}

function StartSavOrEntretientButton({ type }: { type: string }) {
    const [status, setStatus] = useState<string>('idle');

    const label = statusLabelMap[status];

    const [present, dismiss] = useIonModal(CompteRenduSavAndEntretient, {
        type,
        onDismiss() {
            dismiss();
        }
    });

    const handleStatus = () => {
        const st = statusMap[status];
        setStatus(st);

        if (st === 'compteRendu') {
            present();
        }
    }

    return (
        label && (
            <IonButton expand={"block"} onClick={() => handleStatus()}>
                {label}
            </IonButton>
        )
    )
}
