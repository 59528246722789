export interface FacadeVt {
    wallTypeFacade: WallTypeFacade;
    wallAndInsulation: {
        thicknessWall: number;
        insulationType: string;
        thicknessInsulation: number;
        insulatingPosition: 'onInsideWall' | 'onOutsideWall';
        insideWallLength: number;
        insideWallHeight: number;
        wallEnvironmentType: WallEnvironmenType;
    }
}

export enum WallEnvironmenType {
    OUTDOOR = 'OUTDOOR',
    UNHEATED_ROOM = 'UNHEATED_ROOM',
    VERANDA = 'VERANDA',
}

export enum WallTypeFacade {
    CLASSIC_HOLLOW_BRICK = 'CLASSIC_HOLLOW_BRICK',
    TENDER_STONE = 'TENDER_STONE',
    CINDER_BLOCK = 'CINDER_BLOCK',
}
