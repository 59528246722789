import {
    GlazingAndOrientation,
    HouseConfiguration,
    TypeConstruction,
    TypeOfWall, VentilationType, WoodWorkType
} from "../../../models/visiteTechnique/habitatVt";
import {Energy, RadiatorType} from "../../../models/visiteTechnique/pacAirEauVt";
import {Photovoltaic, TypeTiles} from "../../../models/visiteTechnique/photovoltaic";
import {ThermodynamicBalloonVt} from "../../../models/visiteTechnique/thermodynamicBalloonVt";
import {HighFloor, TypeEnvironment} from "../../../models/visiteTechnique/highFloor";
import {EnvironmentType} from "../../../models/visiteTechnique/lowFloor";
import {Windows} from "../../../models/visiteTechnique/windows";
import {FacadeVt, WallEnvironmenType,  WallTypeFacade} from "../../../models/visiteTechnique/facadeVt";

export interface RapportVisiteTechniqueFormModel {
    observations: {
        technics: string;
        financials: string;
        others: string;
        customerSignature: string;
        technicianSignature: string;
    },

    habitat: {
        electricity: {
            typeBranchement: 'MONOPHASE';
            powerKva: 3 | 6 | 9 | 12;
        } | {
            typeBranchement: 'TRIPHASE';
            powerKva: 12 | 15 | 18 | 21;
        };

        walls: {
            thickness: '<60' | '>60';
            type: TypeOfWall;
        };

        additionnalInformation: {
            areaToHeat: number;
            heights: number;
        };

        lastMajorRenovationYear: 'before1974' | '1974_1981' | '1982_1988' | '1989_1999' | '2000_2004' | '2005_2011' | 'after2012';
        houseConfiguration: HouseConfiguration;
        typeConstruction: TypeConstruction;
        glazingAndOrientation: GlazingAndOrientation;
        woodWorkType: WoodWorkType;
        ventilationType: VentilationType;
        numberOfPeopleInTheHouse: number
    },

    pacAirEau: {
        surface: {
            totalVolume: number;
            averageHeight: number;
        },

        complementaryInformation: {
            screedMadeByTheCustomer: boolean;
            floor: boolean;

            rdc: {
                volume: number
                heatingType: string;
            };
            firstFloor: {
                volume: number;
                heatingType: string;
            };
            secondFloor: {
                volume: number;
                heatingType: string;
            };

            permanentlyHeatedFloor: {
                value: boolean; // si true ensemble étage chauffé en permanence
                when?: string;
                why?: string;
            };

            radiatorType: RadiatorType;
            boilerStartTemperature: number;
            principalEnergy: Energy;
            secondaryEnergy: Energy;
            estimatedPrimaryAndSecondaryUse: string;
            pipeDiameter: number;
        },

        ecsIntegratedInTheOldBoiler: boolean
    },

    pacAirAir: {
        splits: Array<{
            size: number;
            location: string;
            frigoLength: number;
            surface: number;
            volume: number;
        }>
    }

    photovoltaic: {
        productAlreadyInstalled: 'aps' | 'enphase' | 'cube' | 'resale',
        dimensions: {
            lowRoofHeight: number;
            topRoofHeight: number;
            availableLength: number;
            rampant: number;
            slope: number;
        },
        typeTiles: TypeTiles,
        installation: 'mainHouse' | 'outbuildingOrGarage',
        ifDependenceAlimentation: {
            typeBranchement: 'MONOPHASE' | 'TRIPHASE';
            cableSection: string;
        },
        charpente: 'mettalic' | 'wood'
    },

    thermodynamicBalloonVt: {
        partToBeInstalled: string;
        roomHeight: number;
        roomArea: number;
        gaine: 'yes' | 'no'
    },

    highFloor: {
        thickness: number;
        insulationType: string;
        insulationThickness: number;
        length: number;
        width: number;
        typeEnvironment: TypeEnvironment
    },

    lowFloor: {
        environmentType: EnvironmentType;
    },

    windows: {
        type: string;
        heightWidth: string;
        numbers: number;
    },

    facades: Array<{
        wallTypeFacade: WallTypeFacade;
        wallAndInsulation: {
            thicknessWall: number;
            insulationType: string;
            thicknessInsulation: number;
            insulatingPosition: 'onInsideWall' | 'onOutsideWall';
            insideWallLength: number;
            insideWallHeight: number;
            wallEnvironmentType: WallEnvironmenType
        };
    }>
}

export const wallTypeFrMap: Record<TypeOfWall, string> = {
    BRICKS: "Briques",
    CEMENT: "Béton",
    HOLLOW_BLOCK: "Parpaing creux",
    MOELLON: "Moellon",
    SOLID_BLOCK: "Parpaing plein",
    WOOD: "Bois"
}

// la liste des code de type de mur
export const wallTypeAsArray = Object.keys(TypeOfWall) as TypeOfWall[];

type LastMajorRenovationYear = 'before1974' | '1974_1981' | '1982_1988' | '1989_1999' | '2000_2004' | '2005_2011' | 'after2012';
export const lastMajorRenovationYearFrMap: Record<LastMajorRenovationYear, string> = {
    before1974: 'Avant 1974',
    '1974_1981': '1974-1981',
    '1982_1988': '1982-1988',
    '1989_1999': '1989-1999',
    "2000_2004": "2000-2004",
    "2005_2011": "2005-2011",
    after2012: "Après 2012",
}
export const lastMajorRenovationYearAsArray = Object.keys(lastMajorRenovationYearFrMap) as LastMajorRenovationYear[];

type ProductAlreadyInstalled = 'APS' | 'ENPHASE' | 'CUBE' | 'RESALE';
export const productAlreadyInstalledFrMap: Record<ProductAlreadyInstalled, string> = {
    APS: 'APS',
    ENPHASE: 'Enphase',
    CUBE: 'Cube',
    RESALE: 'Revente'
}
export const productAlreadyInstalledAsArray = Object.keys(productAlreadyInstalledFrMap) as ProductAlreadyInstalled[];

type Installation = 'mainHouse' | 'outbuildingOrGarage'
export const installationFrMap: Record<Installation, string> = {
    mainHouse: 'Maison principal',
    outbuildingOrGarage: 'Dépendance ou garage'
}
export const installationAsArray = Object.keys(installationFrMap) as Installation[];

type Charpente = 'mettalic' | 'wood';
export const charpenteFrMap: Record<Charpente, string> = {
    mettalic: 'Métallique',
    wood: 'Bois'
}
export const charpenteAsArray = Object.keys(charpenteFrMap) as Charpente[];

export const houseConfigurationFrMap: Record<HouseConfiguration, string> = {
    RECTANGLE : 'Réctangle',
    SQUARE : 'Carré',
    INL : 'Construction en L'
}

// la liste des code de type de maison
export const houseConfigurationAsArray = Object.keys(HouseConfiguration) as HouseConfiguration[];

export const typeConstructionFrMap: Record<TypeConstruction, string> = {
    FULL_FOOT : 'Plein Pied',
    FITTED_ATTIC : 'Comble aménagé',
    TWO_LEVELS : '2 niveaux',
}

// la liste des code de type de construction
export const typeConstructionAsArray = Object.keys(TypeConstruction) as TypeConstruction[];

export const glazingAndOrientationFrMap: Record<GlazingAndOrientation, string> = {
    LITTLE_GLAZED : 'Peu vitré',
    MODERATELY_GLAZED : 'Moyennement vitré',
    HIGHLY_GLAZED : 'Fortement vitré',
}

// la liste des code de type de vitrage
export const glazingAndOrientationAsArray = Object.keys(GlazingAndOrientation) as GlazingAndOrientation[];

export const woodWorkTypeFrMap: Record<WoodWorkType, string> = {
    SINGLE_GLAZING : 'Simple vitrage',
    DOUBLE_GLAZING : 'Double vitrage',
    TRIPLE_GLAZING : 'Triple vitrage',
}

// la liste des code de type de Menuiserie
export const woodWorkTypeAsArray = Object.keys(WoodWorkType) as WoodWorkType[];

export const ventilationTypeFrMap: Record<VentilationType, string> = {
    NO_SYSTEM : 'Aucun système',
    TOP_AND_BOTTOM_VENTILATION : 'Ventilation haute et basse',
    CLASSIC_VMC : 'VMC Classique',
    HYGRO_A : 'Hygro A',
    HYGRO_B : 'Hygro B',
    DOUBLE_FLOW : 'Double flux',
}

// la liste des code de type de Menuiserie
export const ventilationTypeFormAsArray = Object.keys(VentilationType) as VentilationType[];

export const radiorTypeFrMap: Record<RadiatorType, string> = {
    CAST_IRON : 'Fonte',
    ALU : 'Alu',
    STEEL : 'Acier',
    HEATED_FLOOR : 'Plancher chauffant',
}

// la liste des code de type de radiateur
export const radiorTypeAsArray = Object.keys(RadiatorType) as RadiatorType[];

export const principalEnergyFrMap: Record<Energy, string> = {
    FUEL : 'Fioul',
    GAS : 'Gaz',
    ELECTRIC : 'Éléctrique',
    WOOD : 'Bois'
}

// la liste des code de type d'énergie
export const principalEnergyAsArray = Object.keys(Energy) as Energy[];

export const secondaryEnergyFrMap: Record<Energy, string> = {
    FUEL : 'Fioul   ',
    GAS : 'Gaz',
    ELECTRIC : 'Éléctrique',
    WOOD : 'Bois'
}

// la liste des codes de type d'énergie
export const secondaryEnergyAsArray = Object.keys(Energy) as Energy[];

export const typeTilesFrMap: Record<TypeTiles, string> = {
    ROMANE : 'Romane',
    CANAL : 'Canal',
    SLATE : 'Ardoise',
    FIBRO : 'Fibro',
    PLATE : 'Plate',
    GROUND : 'Sol'
};

export const typeTilesAsArray = Object.keys(TypeTiles) as TypeTiles[];

export const typeEnvironmentFrMap: Record<TypeEnvironment, string> = {
    OUTDOOR : 'Extérieur',
    HEATED_ROOM : 'Local chauffé',
    UNDER_UNINSULATED_ATTIC : 'Sous comble non isolé',
    UNDER_INSULATED_ATTIC : 'Sous comble isolé',
};

export const typeEnvironmentAsArray = Object.keys(TypeEnvironment) as TypeEnvironment[];

export const environmentTypeFrMap: Record<EnvironmentType, string> = {
    ON_CRAWL_SPACE : 'Sur vide sanitaire',
    OUTDOOR : 'Extérieur',
    ON_SOLID_GROUND : 'Sur terre plein',
    HEATED_ROOM : 'Local chauffé',
    ON_BURIED_CELLAR : 'Sur cave entérrée',
}

export const environmentTypeAsArray = Object.keys(EnvironmentType) as EnvironmentType[];


export const wallTypeFacadeFrMap: Record<WallTypeFacade, string> = {
    CLASSIC_HOLLOW_BRICK : 'Brique creuse classique',
    TENDER_STONE : 'Pierre tendre',
    CINDER_BLOCK : 'Parpaing',
}

export const wallTypeFacadeAsArray = Object.keys(WallTypeFacade) as WallTypeFacade[];

export const wallEnvironmenTypeFrMap: Record<WallEnvironmenType, string> = {
    OUTDOOR : 'Extérieur',
    UNHEATED_ROOM : 'Local non chauffé',
    VERANDA : 'Véranda',
}

export const wallEnvironmenTypeAsArray = Object.keys(WallEnvironmenType) as WallEnvironmenType[];
