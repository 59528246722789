import axios, {AxiosRequestConfig} from "axios";
import {useAuthStore} from "../components/Auth/auth.store";
import {useActiveCompanyStore, useCompanyStore} from "../components/Company/company.store";

// const http = axios.create({ baseURL: 'http://146.59.199.147:60450/api/index.php' });
export const INTERVENTION_BASE_URL = 'https://p92maq0ofos1jtnq-interventions-kamess-bo.amdp-sales.com';
const interventionHttp = axios.create({ baseURL: INTERVENTION_BASE_URL });

const getRequestHeaders = () => {
    const token = useAuthStore.getState().token || '';
    const login = useAuthStore.getState().user?.login || '';
    const entity = getActiveEntity();

    return {
        'x-authorization-token': `${login}:${token}`,
        'x-entity': entity,
    };
};

// Une copie en mode getter de useActiveCompanyId()
const getActiveEntity = () => {
    return useActiveCompanyStore.getState().activeCompanyId || useCompanyStore.getState().lastActiveCompanyId;
}

const requestInterceptor = (config: AxiosRequestConfig) => {
    config.headers = {
        ...config.headers,
        ...getRequestHeaders(),
    };

    return config;
}

const errorInterceptor = (error: any) => {
    const {config, data} = error.response;

    return Promise.reject(error);
}

interventionHttp.interceptors.request.use(requestInterceptor);
interventionHttp.interceptors.response.use(undefined, errorInterceptor);

export { interventionHttp };
