import {Controller, useController, useForm} from "react-hook-form";
import {CompteRenduSavAndEntretientFormModel} from "./Form";
import {
    IonButton,
    IonCheckbox, IonCol,
    IonContent,
    IonFooter,
    IonHeader, IonIcon,
    IonItem,
    IonLabel,
    IonPage,
    IonRadio,
    IonRadioGroup, IonRow,
    IonTextarea, IonThumbnail,
    IonTitle,
    IonToolbar, useIonLoading, useIonToast
} from "@ionic/react";
import React from "react";
import InterventionLabel from "../Shared/InterventionLabel";
import {useSignaturePad} from "../SignaturePad/useSignaturePad";
import {useRapportVisiteTechniqueContext} from "../RapportVisiteTechnique/RapportVisiteTechniqueContext";

export default function CompteRenduSavAndEntretient({ type, onDismiss }: { type: string, onDismiss?(): void }) {
    const { control } = useForm<CompteRenduSavAndEntretientFormModel>();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Compte Rendu <InterventionLabel type={type} /></IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <Controller
                    control={control}
                    name={'warrantyStatus'}
                    render={
                        ({ field: { value, onChange, } }) => {
                            return (
                                <IonRadioGroup value={value} onIonChange={onChange}>
                                    <IonItem>
                                        <IonLabel>Garantie</IonLabel>
                                        <IonRadio slot="end" value="GUARANTEED"></IonRadio>
                                    </IonItem>

                                    <IonItem>
                                        <IonLabel>Hors Garantie</IonLabel>
                                        <IonRadio slot="end" value="OUT_OF_WARRANTY"></IonRadio>
                                    </IonItem>
                                </IonRadioGroup>
                            )
                        }
                    }
                />

                <Controller
                    control={control}
                    name={'problemDescription'}
                    render={
                        ({ field: { value, onChange, } }) => {
                            return (
                                <IonItem>
                                    <IonLabel position={"stacked"}>Actions menées</IonLabel>
                                    <IonTextarea value={value} onIonInput={onChange} rows={4} placeholder="Actions menées"></IonTextarea>
                                </IonItem>
                            )
                        }
                    }
                />

                <Controller
                    control={control}
                    name={'action'}
                    render={
                        ({ field: { value, onChange, } }) => {
                            return (
                                <IonItem>
                                    <IonLabel position={"stacked"}>Descriptif du problème rencontré</IonLabel>
                                    <IonTextarea value={value} onIonInput={onChange} rows={4} placeholder="Descriptif du problème rencontré"></IonTextarea>
                                </IonItem>
                            )
                        }
                    }
                />

                <Controller
                    control={control}
                    name={'partToOrder'}
                    render={
                        ({ field: { value, onChange, } }) => {
                            return (
                                <IonItem>
                                    <IonLabel position={"stacked"}>Pièce(s) à commander</IonLabel>
                                    <IonTextarea value={value} onIonInput={onChange} rows={4} placeholder="Pièce(s) à commander"></IonTextarea>
                                </IonItem>
                            )
                        }
                    }
                />

                <IonRow>
                    <SignatureCol label={'Signature du client'} name={'customerSignature'}  control={control}/>

                    <SignatureCol label={'Signature du technicien'} name={'technicianSignature'} control={control} />
                </IonRow>
            </IonContent>

            <IonFooter>
                <IonRow>
                    <IonCol>
                        <IonButton expand={"block"} fill="clear" onClick={() => onDismiss && onDismiss()}>Annuler</IonButton>
                    </IonCol>

                    <IonCol>
                        <SaveButton />
                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonPage>
    )
}

function SaveButton() {
    const [present, dismiss] = useIonLoading();

    const [presentToast] = useIonToast();

    const { onDismiss } = useRapportVisiteTechniqueContext();

    return (
        <IonButton
            expand={"block"}
            onClick={() => present({
                message: 'Sauvegarde du compte rendu en cours',
                duration: 3000,
                onDidDismiss() {
                    onDismiss!(); // Dismiss le modal

                    presentToast({
                        message: 'Compte rendu sauvegardé avec succès',
                        duration: 1500,
                        position: 'bottom'
                    });
                }
            })}
        >Enregistrer</IonButton>
    )
}

const signatureIcon = '/assets/icon/signature.svg';

function SignatureCol({ name, label, control }: { name: string, label: string, control: any }) {
    const {
        field: { value: signature, onChange },
    } = useController({
        control,
        name,
        defaultValue: "",
    });

    const persentSignaturePad = useSignaturePad({
        onDismiss(data, role) {
            if (role !== 'confirm') {
                return;
            }

            onChange(data);
        }
    });

    return (
        <IonCol className={"ion-no-padding"}>
            <IonItem button detail={true} onClick={() => persentSignaturePad()}>
                {
                    signature ? (
                        <IonThumbnail slot="start">
                            <img alt="Signature" src={signature} />
                        </IonThumbnail>
                    ) : (
                        <IonIcon icon={signatureIcon} slot="start"></IonIcon>
                    )
                }
                <IonLabel>
                    <h3>{label}</h3>
                </IonLabel>
            </IonItem>
        </IonCol>
    )
}
