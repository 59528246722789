export interface PacAirEauVt {
    surface: {
        totalVolume: number;
        averageHeight: number;
    }
    complementaryInformation: {
        screedMadeByTheCustomer: boolean; // chape beton realise par le client
        detailedPlanPhotos: string[];// plan détaillé en photos + autres photos si besoin
        multiStoreyHouse: boolean;
        rdc: {
            volume: number
            heatingType: string;
        }
        firstFloor: {
            volume: number;
            heatingType: string;
        }
        secondFloor: {
            volume: number;
            heatingType: string;
        }
        desiredTemperature: {
            rdc: number;
            firstFloor: number;
            secondFloor: number;
        }
        permanentlyHeatedFloor: {
            value: boolean; // si true ensemble étage chauffé en permanence
            when?: string;
            why?: string;
        }
        radiatorType: RadiatorType;
        boilerStartTemperature: number;
        principalEnergy: Energy;
        secondaryEnergy: Energy;
        estimatedPrimaryAndSecondaryUse: string;
        boilerPhotos: string[]; // Infos chaudière: ne demande que des photos
        pipeDiameter: number;
    }

    ecsIntegratedInTheOldBoiler: boolean; // ECS intégré à l'ancienne chaudière
    energyInvoicesPhotos: string[]; // Consommation estimée. Utilisation des Photos des factures énergetiques FIOUL / GAZ pour le calcul des estimations
}

export enum RadiatorType {
    CAST_IRON = 'CAST_IRON',
    ALU = 'ALU',
    STEEL = 'STEEL',
    HEATED_FLOOR = 'HEATED_FLOOR',
}

export enum Energy {
    FUEL = 'FUEL',
    GAS = 'GAS',
    ELECTRIC = 'ELECTRIC',
    WOOD = 'WOOD',
}