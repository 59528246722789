export interface Photovoltaic {
    productAlreadyInstalled: 'APS' | 'ENPHASE' | 'CUBE' | 'RESALE';
    dimensions: {
        lowRoofHeight: number;
        topRoofHeight: number;
        availableLength: number;
        rampant: number;
        slope: number;
    }
    typeTiles: TypeTiles;
    installation: 'mainHouse' | 'outbuildingOrGarage';
    ifDependenceAlimentation: {
        typeBranchement: 'MONOPHASE' | 'TRIPHASE';
        cableSection: string;
    }
    charpente: 'mettalic' | 'wood';
}

export enum TypeTiles {
    ROMANE = 'ROMANE',
    CANAL = 'CANAL',
    SLATE = 'SLATE',
    FIBRO = 'FIBRO',
    PLATE = 'PLATE',
    GROUND = 'GROUND',
}
