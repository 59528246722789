import * as React from 'react'
import {useContext} from 'react'
import {ProductTypology} from "../../models/intervention.model";

interface ContextModel {
    interventionId?: string,
    proposalId?: string,
    typologies: ProductTypology[],
    currentStep: number,
    isLastStep: boolean;
    next(): void,
    prev(): void,
    onDismiss?(): void;
}

export const RapportVisiteTechniqueContext = React.createContext<ContextModel>({
    interventionId: '',
    proposalId: '',
    typologies: [],
    currentStep: 0,
    isLastStep: false,
    next() {},
    prev() {},
    onDismiss() {}
});

export const useRapportVisiteTechniqueContext = () => useContext<ContextModel>(RapportVisiteTechniqueContext);
