import {useProposalDetail} from "../AffaireDetail/useProposalDetail";
import {uniq} from 'lodash';

export function useAllInterventionProductTypologies(proposalId: string) {
    const {isLoading, isError, data: proposal} = useProposalDetail(proposalId);

    const typologies = (proposal?.lines || [])
        .map(line => line.array_options?.options_product_typology)
        .filter(e => !!e)
    ;

    return { isLoading, isError, typologies: uniq(typologies) };
}

