export interface LowFloor {
    environmentType: EnvironmentType;
}

export enum EnvironmentType {
    ON_CRAWL_SPACE = 'ON_CRAWL_SPACE',
    OUTDOOR = 'OUTDOOR',
    ON_SOLID_GROUND = 'ON_SOLID_GROUND',
    HEATED_ROOM = 'HEATED_ROOM',
    ON_BURIED_CELLAR = 'ON_BURIED_CELLAR',
}