import create from "zustand";
import { immer } from 'zustand/middleware/immer';
import { devtools, persist } from 'zustand/middleware'
import { User } from "../../models/user.model";

interface AuthState {
    token: string;
    entity: string;
    user: User | null;
    permissions?: any;
    setToken(token: string, entity: string): void;
    setUser(user: User): void;
    logout(): void;
}

const initialState = {
    token: '',
    entity: '0',
    user: null,
    permissions: {},
}

export const useAuthStore = create<AuthState>()(
    devtools(
        persist(
            immer((set) => {
                return {
                    ...initialState,
                    setToken(token: string, entity: string) {
                        set(state => {
                            state.token = token;
                            state.entity = entity;
                        })
                    },
                    setUser(user: User) {
                        set(state => {
                            state.user = user;
                        })
                    },
                    logout: () => set(initialState)
                }
            }),
            {
                name: 'auth',
            }
        )
    )
);

export function useSetToken() {
    return useAuthStore(state => state.setToken);
}

export function useUser() {
    return useAuthStore(state => state.user) as User;
}

export function useSetUser() {
    return useAuthStore(state => state.setUser);
}

export function useLogout() {
    return useAuthStore(state => state.logout);
}

/**
 * Est connecté si token n'est pas vide
 */
export function useIsAuthenticated() {
    const token = useAuthStore(state => state.token);

    return !!token;
}