export default function InterventionLabel({ type }: { type: string }) {
    return (
        <>
            {{
                VISITE_TECHNIQUE: 'Visite Technique',
                POSE: 'Pose',
                SAV: 'SAV',
                ENTRETIENT: 'Entretien'
            }[type]}
        </>
    )
}
