import React from "react";
import {
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption
} from "@ionic/react";
import {Controller} from "react-hook-form";
import {
    charpenteAsArray,
    charpenteFrMap,
    productAlreadyInstalledAsArray,
    productAlreadyInstalledFrMap,
    typeTilesAsArray,
    typeTilesFrMap
} from "./Form";

const Photovoltaique: React.FC = () => {
    return (
        <IonContent>
            <IonList>
                <MyIonSelect title={"Produit déjà installé"} nameField={'photovoltaic.productAlreadyInstalled'}
                             asArray={productAlreadyInstalledAsArray} frMap={productAlreadyInstalledFrMap} />
            </IonList>

            <IonList>
                <IonListHeader>
                    <IonLabel>Dimensions</IonLabel>
                </IonListHeader>

                <MyInputSimple title={"Hauteur bas de toit"} nameField={'photovoltaic.dimensions.lowRoofHeight'}  typeData={'number'} />

                <MyInputSimple title={"Hauteur haut de toit"} nameField={"photovoltaic.dimensions.topRoofHeight"}  typeData={'number'} />

                <MyInputSimple title={"Longueur disponible pour PV"} nameField={"photovoltaic.dimensions.availableLength"}  typeData={'number'} />

                <MyInputSimple title={"Rampant"} nameField={"photovoltaic.dimensions.rampant"}  typeData={'number'} />

                <MyInputSimple title={"Pente"} nameField={"photovoltaic.dimensions.slope"}  typeData={'number'} />

                <MyIonSelect title={"Type de tuile"} nameField={'photovoltaic.dimensions.typeTiles'} asArray={typeTilesAsArray} frMap={typeTilesFrMap} />

                <MyBoolean title={"Installation"} nameField={'photovoltaic.dimensions.installation'} choiceOne={'Maison principale'} choiceTwo={'Dépendance ou garage'} />

                <IonList>
                    <MyBoolean title={"Si dépendance: alimentation"} nameField={"photovoltaic.dimensions.ifDependenceAlimentation.typeBranchement"} choiceOne={'MONOPHASE'} choiceTwo={'TRIPHASE'} />
                    <MyInputSimple title={"Section du cable"} nameField={'photovoltaic.dimensions.ifDependenceAlimentation.cableSection'} typeData={'string'} />
                </IonList>

            </IonList>

            <IonList>
                <MyIonSelect title={"Charpente"} nameField={'photovoltaic.charpente'} asArray={charpenteAsArray} frMap={charpenteFrMap} />
            </IonList>
        </IonContent>
    )
}

interface MyInputSimpleProps {
    title: string;
    nameField: string;
    typeData: any
};

interface MyIonSelectProps {
    title: string;
    nameField: string;
    asArray: any[];
    frMap: any
};

interface MyBooleanProp {
    title: string;
    nameField: string;
    choiceOne: string;
    choiceTwo: string;
};

function MyIonSelect({nameField, title, asArray, frMap}: MyIonSelectProps) {
    return(
        <>
            <Controller
                name={nameField}
                defaultValue={''}
                render={
                    ({ field: { value, onChange, } }) => {
                        return (
                            <IonItem>
                                <IonLabel>{title}</IonLabel>
                                <IonSelect placeholder={title} value={value} onIonChange={onChange}>
                                    {
                                        asArray.map(
                                            type => {
                                                const label = frMap[type];

                                                return <IonSelectOption key={`wallType-${type}`} value={type}>{label}</IonSelectOption>
                                            }
                                        )
                                    }
                                </IonSelect>
                            </IonItem>
                        )
                    }}
            />
        </>
    )
}

function MyInputSimple({title, nameField, typeData}: MyInputSimpleProps) {
    return (
        <>
            <Controller
                name={nameField}
                render={
                    ({field}) => {
                        return (
                            <IonItem>
                                <IonLabel position={"stacked"}>{title}</IonLabel>
                                <IonInput value={field.value} onIonChange={field.onChange}  type={typeData} placeholder={title} />
                            </IonItem>
                        )
                    }
                }
            />
        </>
    )
}

function MyBoolean({title, nameField, choiceOne, choiceTwo}: MyBooleanProp) {
    return(
        <>
            <IonListHeader>
                <IonLabel>{title}</IonLabel>
            </IonListHeader>

            <Controller
                name={nameField}
                defaultValue={''}
                render={
                    ({ field: { value, onChange, } }) => {
                        return (
                            <IonRadioGroup value={value} onIonChange={onChange}>
                                <IonItem>
                                    <IonLabel>{choiceOne}</IonLabel>
                                    <IonRadio slot="end" value={choiceOne}></IonRadio>
                                </IonItem>

                                <IonItem>
                                    <IonLabel>{choiceTwo}</IonLabel>
                                    <IonRadio slot="end" value={choiceTwo}></IonRadio>
                                </IonItem>
                            </IonRadioGroup>
                        )
                    }
                }
            />
        </>

    )
}

export default Photovoltaique;
