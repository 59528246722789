import React from "react";
import {IonAccordion, IonAccordionGroup, IonContent, IonInput, IonItem, IonLabel} from "@ionic/react";
import {Controller} from "react-hook-form";

const PacAirAir: React.FC = () => {
    const splits = [1, 2, 3, 4, 5];

    return (
        <IonContent>
            {
                splits.map(
                    (split, index) => {
                        return (
                            <IonAccordionGroup key={`split-${index}`}>
                                <IonAccordion>
                                    <IonItem slot="header" color="light">
                                        <IonLabel>SPLIT {split}</IonLabel>
                                    </IonItem>
                                    <div className="ion-padding" slot="content">
                                        <MySplit index={index}  />
                                    </div>
                                </IonAccordion>
                            </IonAccordionGroup>
                        )
                    }
                )
            }
        </IonContent>
    )
}

interface MyInputSimpleProps {
    title: string;
    nameField: string;
    typeData: any
};

function MyInputSimple({title, nameField, typeData}: MyInputSimpleProps) {
    return (
        <>
            <Controller
                name={nameField}
                render={
                    ({field}) => {
                        return (
                            <IonItem>
                                <IonLabel position={"stacked"}>{title}</IonLabel>
                                <IonInput value={field.value} onIonChange={field.onChange}  type={typeData} placeholder={title} />
                            </IonItem>
                        )
                    }
                }
            />
        </>
    )
}

function MySplit({ index }: { index: number }) {
    const names = {
        size: `pacAirAir.splits.${index}.size`,
        location: `pacAirAir.splits.${index}.location`,
        frigoLength: `pacAirAir.splits.${index}.frigoLength`,
        surface: `pacAirAir.splits.${index}.surface`,
        volume: `pacAirAir.splits.${index}.volume`,
    }

    return(
        <>
            <MyInputSimple title={"Taille"} nameField={names.size} typeData={'number'} />
            <MyInputSimple title={"Emplacement"} nameField={names.location} typeData={'string'} />
            <MyInputSimple title={"Longueur frigo"} nameField={names.frigoLength} typeData={'number'} />
            <MyInputSimple title={"Surface"} nameField={names.surface} typeData={'number'} />
            <MyInputSimple title={"Volume"} nameField={names.volume} typeData={'number'} />
        </>
    )
}

export default PacAirAir;
