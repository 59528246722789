import {interventionHttp} from "../../http";
import {Endpoint} from "../endpoint.enum";
import {Intervention} from "../../models/intervention.model";

export async function getIntervention(id: string) {
    const url = Endpoint.intervention.replace(':id', id);

    const {data} = await interventionHttp.get<Intervention>(url);

    return data;
}