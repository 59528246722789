export interface HighFloor {
    thickness: number;
    insulationType: string;
    insulationThickness: number;
    length: number;
    width: number;
    typeEnvironment: TypeEnvironment;
}

export enum TypeEnvironment {
    OUTDOOR = 'OUTDOOR',
    HEATED_ROOM = 'HEATED_ROOM',
    UNDER_UNINSULATED_ATTIC = 'UNDER_UNINSULATED_ATTIC',
    UNDER_INSULATED_ATTIC = 'UNDER_INSULATED_ATTIC',
}
