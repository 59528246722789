import React, {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {RapportVisiteTechniqueFormModel} from "../components/RapportVisiteTechnique/Form";
import {DevTool} from "@hookform/devtools";
import {RapportVisiteTechniqueContext} from "../components/RapportVisiteTechnique/RapportVisiteTechniqueContext";
import {ProductTypology} from "../models/intervention.model";
import ProductReport from "../components/RapportVisiteTechnique/ProductReport";

interface RapportVisiteTechniqueProps {
    interventionId: string;
    proposalId: string;
    typologies: ProductTypology[];
    onDismiss?(): void;
}

const RapportVisiteTechnique: React.FC<RapportVisiteTechniqueProps> = (props: RapportVisiteTechniqueProps) => {
    const methods = useForm<RapportVisiteTechniqueFormModel>();

    const [currentStep, setCurrentStep] = useState<number>(0);

    const totalStep = props.typologies.length + 2; // on rajoute pour observations et habitat
    const isLastStep = currentStep === (totalStep - 1);

    const next = () => setCurrentStep(
        currentStep < totalStep - 1
            ? currentStep + 1
            : currentStep
    );
    const prev = () => setCurrentStep(
        currentStep > 0
            ? currentStep - 1
            : currentStep
    );

    return (
        <RapportVisiteTechniqueContext.Provider value={{
            ...props,
            currentStep,
            isLastStep,
            next,
            prev,
        }}>
            <FormProvider {...methods}>
                <ProductReport />
            </FormProvider>

            <DevTool control={methods.control} />
        </RapportVisiteTechniqueContext.Provider>
    )
}

export default RapportVisiteTechnique;
