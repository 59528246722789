import {useMutation} from "@tanstack/react-query";
import {editReport} from "../../calls/Interventions/editReport";
import {useRapportVisiteTechniqueContext} from "../RapportVisiteTechnique/RapportVisiteTechniqueContext";
import {IonButton} from "@ionic/react";
import React from "react";
import {useFormContext} from "react-hook-form";

export default function InterventionReportNextButton() {
    const { interventionId, next } = useRapportVisiteTechniqueContext();
    const { getValues } = useFormContext();
    const { mutate, isLoading } = useMutation((report: any) => editReport(interventionId!, report));

    const handleNext = () => {
        next();
        mutate(getValues());
    }

    return (
        <IonButton expand={"block"} onClick={handleNext}>Suivant</IonButton>
    )
}
