import {IonItem, IonLabel, IonRadio, IonRadioGroup} from "@ionic/react";
import {Controller} from "react-hook-form";
import React from "react";

export interface HabitatVt {
    electricity: {
        typeBranchement: 'MONOPHASE' | 'TRIPHASE';
        powerKva: 3 | 6 | 9 | 12 | 15 | 18 | 21;
    }
    walls: {
        thickness: '<60' | '>60';
        type: TypeOfWall;
    }
    additionnalInformation: {
        areaToHeat: number;
        heights: number;
    }
    lastMajorRenovationYear: 'before1974' | '1974_1981' | '1982_1988' | '1989_1999' | '2000_2004' | '2005_2011' | 'after2012';
    houseConfiguration: HouseConfiguration;
    typeConstruction: TypeConstruction;
    glazingAndOrientation: GlazingAndOrientation;
    woodWorkType: WoodWorkType;
    ventilationType: VentilationType;
    numberOfPeopleInTheHouse: number
}

export enum TypeOfWall {
    BRICKS = 'BRICKS',
    MOELLON = 'MOELLON',
    CEMENT = 'CEMENT',
    WOOD = 'WOOD',
    HOLLOW_BLOCK = 'HOLLOW_BLOCK', // parpaing creux
    SOLID_BLOCK = 'SOLID_BLOCK', // parpaing plein
}

export enum HouseConfiguration {
    RECTANGLE = 'RECTANGLE',
    SQUARE = 'SQUARE',
    INL = 'INL', // construction en L
}

export enum TypeConstruction {
    FULL_FOOT = 'FULL_FOOT',
    FITTED_ATTIC = 'FITTED_ATTIC',
    TWO_LEVELS = 'TWO_LEVELS',
}

export enum GlazingAndOrientation {
    LITTLE_GLAZED = 'LITTLE_GLAZED',
    MODERATELY_GLAZED = 'MODERATELY_GLAZED',
    HIGHLY_GLAZED = 'HIGHLY_GLAZED',
}

export enum WoodWorkType {
    SINGLE_GLAZING = 'SINGLE_GLAZING',
    DOUBLE_GLAZING = 'DOUBLE_GLAZING',
    TRIPLE_GLAZING = 'TRIPLE_GLAZING',
}

export enum VentilationType {
    NO_SYSTEM = 'NO_SYSTEM',
    TOP_AND_BOTTOM_VENTILATION = 'TOP_AND_BOTTOM_VENTILATION',
    CLASSIC_VMC = 'CLASSIC_VMC',
    HYGRO_A = 'HYGRO_A',
    HYGRO_B = 'HYGRO_B',
    DOUBLE_FLOW = 'DOUBLE_FLOW',
}
