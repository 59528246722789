import {
    IonButtons,
    IonChip, IonCol,
    IonContent, IonGrid,
    IonHeader, IonIcon, IonItem,
    IonLabel, IonList, IonMenuButton,
    IonPage, IonRow,
    IonSegment,
    IonSegmentButton, IonTitle,
    IonToolbar
} from '@ionic/react';
import {useState} from "react";
import {arrowForward} from "ionicons/icons";
import styled from "@emotion/styled";
import {useInterventionUI} from "../components/Shared/useInterventionUI";
import {useInterventionsQuery} from "../components/Interventions/useInterventionQuery";
import {AllInterventionStatus, Intervention, InterventionType} from "../models/intervention.model";
import {useUser} from "../components/Auth/auth.store";
import {DateTime} from "luxon";

const Interventions: React.FC = () => {
    const [segment, setSegment] = useState<'todo' | 'done' | 'to_schedule'>('todo');

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons>
                    <IonTitle>Interventions</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonSegment value={segment} onIonChange={(e) => setSegment(e.detail.value as 'todo')}>
                    <IonSegmentButton value="todo">
                        <IonLabel>À faire</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="to_schedule">
                        <IonLabel>Non planifiées</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="done">
                        <IonLabel>Terminées</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="cancelled">
                        <IonLabel>Annulées</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                {
                    {
                        'todo': <Todo />,
                        'done': <Done />,
                        'to_schedule': <ToSchedule />,
                        'cancelled': <Cancelled />
                    }[segment]
                }
            </IonContent>
        </IonPage>
    );
};

export default Interventions;

function useInterventions(status: AllInterventionStatus) {
    const user = useUser();

    return useInterventionsQuery({
        technicianId: user.id,
        status,
    });
}

function Todo() {
    const { isLoading, data } = useInterventions(AllInterventionStatus.SCHEDULED);

    const interventions = (data?.docs || []);

    if (isLoading) {
        return <></>;
    }

    if (interventions.length === 0) {
        return (
            <NoInterventions />
        )
    }

    return (
        <IonList inset={true}>
            {
                interventions.map(
                    (intervention, index) => (
                        <InterventionItem key={`todo-intervention-${index}`} intervention={intervention} />
                    )
                )
            }
        </IonList>
    )
}

function Done() {
    const { isLoading, data } = useInterventions(AllInterventionStatus.COMPLETED);

    const interventions = (data?.docs || []);

    if (isLoading) {
        return <></>;
    }

    if (interventions.length === 0) {
        return (
            <NoInterventions />
        )
    }

    return (
        <IonList inset={true}>
            {
                interventions.map(
                    (intervention, index) => (
                        <InterventionItem key={`done-intervention-${index}`} intervention={intervention} />
                    )
                )
            }
        </IonList>
    )
}

function ToSchedule() {
    const { isLoading, data } = useInterventions(AllInterventionStatus.TO_SCHEDULE);

    const interventions = (data?.docs || []);

    if (isLoading) {
        return <></>;
    }

    if (interventions.length === 0) {
        return (
            <NoInterventions />
        )
    }

    return (
        <IonList inset={true}>
            {
                interventions.map(
                    (intervention, index) => (
                        <InterventionItem key={`toSchedule-intervention-${index}`} intervention={intervention} />
                    )
                )
            }
        </IonList>
    )
}

function Cancelled() {
    const { isLoading, data } = useInterventions(AllInterventionStatus.CANCELLED);

    const interventions = (data?.docs || []);

    if (isLoading) {
        return <></>;
    }

    if (interventions.length === 0) {
        return (
            <NoInterventions />
        )
    }

    return (
        <IonList inset={true}>
            {
                interventions.map(
                    (intervention, index) => (
                        <InterventionItem key={`toSchedule-intervention-${index}`} intervention={intervention} />
                    )
                )
            }
        </IonList>
    )
}

function NoInterventions() {
    return (
        <IonGrid>
            <IonRow>
                <IonCol>
                    <div className="ion-text-center">
                        <h3>Pas d'intervention</h3>
                        <p>Vous n'avez aucune intervention prévue</p>
                    </div>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

interface InterventionItemProps {
    intervention: Intervention;
}

function InterventionItem({ intervention }: InterventionItemProps) {
    const { id, date, customer, reference, place: { address, zipCode, town, }, type, typeData } = intervention;

    const addressText = [
        address,
        zipCode,
        town,
    ].filter(i => !!i).join(', ');

    const { label, chipColor } = useInterventionUI({ type });

    return (
        <IonItem routerLink={`/app/Interventions/${id}`} button detail={true}>
            <IonLabel>
                <h3>{customer.name}</h3>
                <p>{addressText}</p>

                {
                    type === InterventionType.POSE && (
                        <StyledRange>
                            <span>{formatPoseDate(typeData.theoricalStartDate)}</span>
                            <IonIcon icon={arrowForward} />
                            <span>{formatPoseDate(typeData.theoricalEndDate)}</span>
                        </StyledRange>
                    )
                }

                {
                    type !== InterventionType.POSE && (
                        <p>{date ? formatDate(date) : '--/--/----'}</p>
                    )
                }
                <IonChip color={chipColor}>{label}</IonChip>
            </IonLabel>
        </IonItem>
    )
}

const formatDate = (date: string) => DateTime.fromISO(date, { zone: "Europe/Paris" }).setLocale('fr').toFormat('dd/MM/yyyy HH:mm');
const formatPoseDate = (date: string) => DateTime.fromISO(date, { zone: "Europe/Paris" }).setLocale('fr').toLocaleString(DateTime.DATE_MED);

const StyledRange = styled.p`
    display: flex;
    align-items: center;

    span:first-of-type {
        margin-right: 2px;
    }

    span:last-of-type {
        margin-left: 2px;
    }
`;
