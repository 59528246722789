import React from "react";
import {
    IonCheckbox,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption
} from "@ionic/react";
import {Controller, useWatch} from "react-hook-form";
import {
    glazingAndOrientationAsArray,
    glazingAndOrientationFrMap,
    houseConfigurationAsArray,
    houseConfigurationFrMap,
    lastMajorRenovationYearAsArray,
    lastMajorRenovationYearFrMap,
    typeConstructionAsArray,
    typeConstructionFrMap,
    ventilationTypeFormAsArray,
    ventilationTypeFrMap,
    wallTypeAsArray,
    wallTypeFrMap,
    woodWorkTypeAsArray,
    woodWorkTypeFrMap
} from "./Form";

const availablePowersByTypeBranchement: Record<'MONOPHASE' | 'TRIPHASE', number[]> = {
    MONOPHASE: [3, 6, 9, 12],
    TRIPHASE: [12, 15, 18, 21],
}

const Habitat: React.FC = () => {
    const typeBranchement = useWatch({ name: 'habitat.electricity.typeBranchement', defaultValue: 'MONOPHASE' }) as 'MONOPHASE';

    // Puissance disponible par type de branchement
    const availablePowers = availablePowersByTypeBranchement[typeBranchement];

    return (
        <IonContent>
            <IonList>
                <IonListHeader>
                    <IonLabel>Électricité / Branchement</IonLabel>
                </IonListHeader>

                <Controller
                    name={'habitat.electricity.typeBranchement'}
                    defaultValue={'MONOPHASE'}
                    render={
                        ({ field: { value, onChange, } }) => {
                            return (
                                <IonRadioGroup value={value} onIonChange={onChange}>
                                    <IonItem>
                                        <IonLabel>Monophasé</IonLabel>
                                        <IonRadio slot="end" value="MONOPHASE"></IonRadio>
                                    </IonItem>

                                    <IonItem>
                                        <IonLabel>Triphasé</IonLabel>
                                        <IonRadio slot="end" value="TRIPHASE"></IonRadio>
                                    </IonItem>
                                </IonRadioGroup>
                            )
                        }
                    }
                />

                <IonListHeader>
                    <IonLabel>Électricité / Puissance KVA</IonLabel>
                </IonListHeader>

                <Controller
                    name={'habitat.electricity.powerKva'}
                    render={
                        ({ field: { value, onChange, } }) => {
                            return (
                                <IonRadioGroup value={value} onIonChange={onChange}>
                                    {
                                        availablePowers.map(
                                            power => {
                                                return (
                                                    <IonItem key={`power-${power}`}>
                                                        <IonLabel>{power}</IonLabel>
                                                        <IonRadio slot="end" value={power}></IonRadio>
                                                    </IonItem>
                                                )
                                            }
                                        )
                                    }
                                </IonRadioGroup>
                            )
                        }
                    }
                />
            </IonList>

            <IonList>
                <IonListHeader>
                    <IonLabel>Murs / Épaisseur</IonLabel>
                </IonListHeader>

                <Controller
                    name={'habitat.walls.thickness'}
                    defaultValue={'<60'}
                    render={
                        ({ field: { value, onChange, } }) => {
                            return (
                                <IonRadioGroup value={value} onIonChange={onChange}>
                                    <IonItem>
                                        <IonLabel>{'< 60'}</IonLabel>
                                        <IonRadio slot="end" value={"<60"}></IonRadio>
                                    </IonItem>

                                    <IonItem>
                                        <IonLabel>{'> 60'}</IonLabel>
                                        <IonRadio slot="end" value={">60"}></IonRadio>
                                    </IonItem>
                                </IonRadioGroup>
                            )
                        }
                    }
                />

                <Controller
                    name={'habitat.walls.type'}
                    defaultValue={''}
                    render={
                        ({ field: { value, onChange, } }) => {
                            return (
                                <IonItem>
                                    <IonLabel>Type de murs</IonLabel>
                                    <IonSelect placeholder="Type" value={value} onIonChange={onChange}>
                                        {
                                            wallTypeAsArray.map(
                                                type => {
                                                    const label = wallTypeFrMap[type];

                                                    return <IonSelectOption key={`wallType-${type}`} value={type}>{label}</IonSelectOption>
                                                }
                                            )
                                        }
                                    </IonSelect>
                                </IonItem>
                            )
                        }}
                />
            </IonList>

            <IonList>
                <IonListHeader>
                    <IonLabel>Informations Complémentaires</IonLabel>
                </IonListHeader>

                <MyInputSimple title={"Superficie à chauffer"} nameField={"habitat.additionnalInformation.areaToHeat"} typeData={'number'} />

                <MyInputSimple title={"Hauteur"} nameField={"habitat.additionnalInformation.heights"} typeData={'number'} />
            </IonList>

            <IonList>
                <MyIonSelect title={"Date dernière grosse rénovation"} nameField={'habitat.lastMajorRenovationYear'}
                             asArray={lastMajorRenovationYearAsArray} frMap={lastMajorRenovationYearFrMap} />
                <MyIonSelect title={"Configuration de la maison"} nameField={"habitat.houseConfiguration"}
                             asArray={houseConfigurationAsArray} frMap={houseConfigurationFrMap} />
                <MyIonSelect title={"Type de construction"} nameField={"habitat.typeConstruction"}
                             asArray={typeConstructionAsArray} frMap={typeConstructionFrMap} />
                <MyIonSelect title={"Vitrage et orientation (Pièce principale"} nameField={'habitat.glazingAndOrientation'}
                             asArray={glazingAndOrientationAsArray} frMap={glazingAndOrientationFrMap} />

                <MyIonSelect title={"Type de menuiserie"} nameField={'habitat.woodWorkType'}
                             asArray={woodWorkTypeAsArray} frMap={woodWorkTypeFrMap} />

            </IonList>

            <MyIonSelect title={"Type de ventilation"} nameField={'habitat.ventilationType'}
                         asArray={ventilationTypeFormAsArray} frMap={ventilationTypeFrMap} />

            <MyInputSimple title={"Nombre de personnes au foyer"} nameField={'habitat.numberOfPeopleInTheHouse'} typeData={'number'} />

        </IonContent>
    )
}

interface MyInputSimpleProps {
    title: string;
    nameField: string;
    typeData: any
};

interface MyBooleanProps {
    title: string;
    nameField: string;
};

interface MyInputNumberProps {
    title: string;
    nameFieldOne: string;
    nameFieldTwo: string;
    fieldOne: string;
    fieldTwo?: string;
};

interface MyIonSelectProps {
    title: string;
    nameField: string;
    asArray: any[];
    frMap: any
}

function MyInputSimple({title, nameField, typeData}: MyInputSimpleProps) {
    return (
        <>
            <Controller
                name={nameField}
                render={
                    ({field}) => {
                        return (
                            <IonItem>
                                <IonLabel position={"stacked"}>{title}</IonLabel>
                                <IonInput value={field.value} onIonChange={field.onChange}  type={typeData} placeholder={title} />
                            </IonItem>
                        )
                    }
                }
            />
        </>
    )
}

function MyCheckbox({title, nameField}: MyBooleanProps) {
    return (
        <Controller
            name={nameField}
            //defaultValue={'<60'}
            render={
                ({ field: { value, onChange, } }) => {
                    return (
                        <IonItem>
                            <IonCheckbox
                                slot="start"
                                checked={value}
                                onIonChange={({ detail: { checked } }) => onChange(checked)}
                            ></IonCheckbox>
                            <IonLabel>{title}</IonLabel>
                        </IonItem>
                    )
                }
            }
        />
    )
}

function MyInputNumberThree({title, nameFieldOne, nameFieldTwo, fieldOne, fieldTwo}: MyInputNumberProps) {
    return (
        <>
            <IonListHeader>
                <IonLabel>{title}</IonLabel>
            </IonListHeader>

            <Controller
                name={nameFieldOne}
                render={
                    ({field}) => {
                        return (
                            <IonItem>
                                <IonLabel position={"stacked"}>{fieldOne}</IonLabel>
                                <IonInput value={field.value} onIonChange={field.onChange}  type={'number'} placeholder={fieldOne} />
                            </IonItem>
                        )
                    }
                }
            />

            <Controller
                name={nameFieldTwo}
                render={
                    ({field}) => {
                        return (
                            <IonItem>
                                <IonLabel position={"stacked"}>{fieldTwo}</IonLabel>
                                <IonInput value={field.value} onIonChange={field.onChange} type={'number'} placeholder={fieldTwo} />
                            </IonItem>
                        )
                    }
                }
            />
        </>
    )
}

function MyIonSelect({nameField, title, asArray, frMap}: MyIonSelectProps) {
    return(
        <>
            <Controller
                name={nameField}
                defaultValue={''}
                render={
                    ({ field: { value, onChange, } }) => {
                        return (
                            <IonItem>
                                <IonLabel>{title}</IonLabel>
                                <IonSelect placeholder={title} value={value} onIonChange={onChange}>
                                    {
                                        asArray.map(
                                            type => {
                                                const label = frMap[type];

                                                return <IonSelectOption key={`wallType-${type}`} value={type}>{label}</IonSelectOption>
                                            }
                                        )
                                    }
                                </IonSelect>
                            </IonItem>
                        )
                    }}
            />
        </>
    )
}

export default Habitat;
