export enum InterventionType {
    VISITE_TECHNIQUE = 'VISITE_TECHNIQUE',
    POSE = 'POSE',
    SAV = 'SAV',
    ENTRETIEN = 'ENTRETIEN',
}

export enum AllInterventionStatus {
    TO_SCHEDULE = 'TO_SCHEDULE',
    SCHEDULED = 'SCHEDULED',
    CANCELLED = 'CANCELLED',
    PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
    COMPLETED = 'COMPLETED',
}

export class Intervention<T extends InterventionType = any, S = any> {
    public id: string;
    public reference: string;
    public type: T;
    public proposal: InterventionProposal;
    public customer: InterventionCustomer;
    public place: InterventionPlace;
    public entity: string;
    public status: AllInterventionStatus;
    public events: Events[];
    public date?: string;
    public reportSaved?: boolean;
    public technician?: InterventionTechnician;
    public createdAt?: string;
    public updatedAt?: string;
    public typeData: S;
}

export class InterventionProposal {
    public id?: string;
    public origin?: string;
    public originId?: string;
}

export class InterventionCustomer {
    public id: string;
    public name: string;
}

export class InterventionPlace {
    public address?: string;
    public zipCode?: string;
    public town?: string;
    public coords?: number[];
}

export class InterventionTechnician {
    public id?: string;
    public name?: string;
}

export class VisiteTechniqueTypeData {
    public duration?: number;
    public desiredInterventionDate?: string;
    public particularConditions?: string;
    public observations?: string;
}

export class VisiteTechnique extends Intervention<InterventionType.VISITE_TECHNIQUE, VisiteTechniqueTypeData> {}

export class PoseTypeData {
    theoricalStartDate: string;
    theoricalEndDate: string;
}

export class Pose extends Intervention<InterventionType.POSE, PoseTypeData> {}

export class Events {
    public type?: string;
    public date?: string;
}

interface InterventionUI {
    typeTranslation: Record<InterventionType, string>;
    typeColorMapping: Record<InterventionType, string>;
    statusTranslation: Record<AllInterventionStatus, string>;
    statusColorMapping: Record<AllInterventionStatus, string>;
}

export const interventionUI: InterventionUI = {
    typeTranslation: {
        [InterventionType.VISITE_TECHNIQUE]: 'Visite technique',
        [InterventionType.POSE]: 'Pose',
        [InterventionType.SAV]: 'SAV',
        [InterventionType.ENTRETIEN]: 'Entretien',
    },
    typeColorMapping: {
        [InterventionType.VISITE_TECHNIQUE]: 'primary',
        [InterventionType.POSE]: 'success',
        [InterventionType.SAV]: 'tertiary',
        [InterventionType.ENTRETIEN]: 'warning',
    },
    statusTranslation: {
        [AllInterventionStatus.TO_SCHEDULE]: 'À Planifier',
        [AllInterventionStatus.SCHEDULED]: 'Programmée',
        [AllInterventionStatus.CANCELLED]: 'Annulée',
        [AllInterventionStatus.PARTIALLY_COMPLETED]: 'Partiellement complétée',
        [AllInterventionStatus.COMPLETED]: 'Terminée',
    },
    statusColorMapping: {
        [AllInterventionStatus.TO_SCHEDULE]: 'medium',
        [AllInterventionStatus.SCHEDULED]: 'primary',
        [AllInterventionStatus.CANCELLED]: 'danger',
        [AllInterventionStatus.PARTIALLY_COMPLETED]: 'warning',
        [AllInterventionStatus.COMPLETED]: 'success',
    }
}

export enum ProductTypology {
    PAC_AIR_AIR = 'PAC_AIR_AIR', // Pompe à chaleur Air Air
    PAC_AIR_EAU = 'PAC_AIR_EAU', // Pompe à chaleur Air Eau
    PHOTOVOLTAIC = 'PHOTOVOLTAIC', // Photovoltaïque
    BALLON_THERMO = 'BALLON_THERMO', // Ballon thermodynamique
}
