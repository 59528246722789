import {isNil, omitBy} from "lodash";
import {Endpoint} from "../endpoint.enum";
import {interventionHttp} from "../../http";
import {PaginationResultModel} from "../../models/common.model";
import {AllInterventionStatus, Intervention, InterventionType} from "../../models/intervention.model";

export type SearchInterventionsPayload = {
    // Les filtres extistants
    status?: AllInterventionStatus;
    technicianId?: string;
    type?: InterventionType;
    customerId?: string;
    customerName?: string;
}

export async function searchInterventions(payload: SearchInterventionsPayload = {}) {
    const dto = omitBy(payload, isNil);

    const {data} = await interventionHttp.get<PaginationResultModel<Intervention>>(Endpoint.interventions,{
        params: {
            ...dto,
            limit: 150,
            sort: '-createdAt',
        }
    });

    return data;
}
