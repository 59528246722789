import React from "react";
import {
    IonCheckbox,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonSelectOption
} from "@ionic/react";
import {Controller} from "react-hook-form";
import {
    principalEnergyAsArray,
    principalEnergyFrMap,
    radiorTypeAsArray,
    radiorTypeFrMap,
    secondaryEnergyAsArray,
    secondaryEnergyFrMap
} from "./Form";

const PacAirEau: React.FC = () => {
    return (
        <IonContent>
            <IonList>
                <IonListHeader>
                    <IonLabel>Surface</IonLabel>
                </IonListHeader>

                <IonItem>
                    <MyInputSimple title={"Volume total"} nameField={'pacAirEau.surface.totalVolume'} typeData={'number'} />

                    <MyInputSimple title={"Hauteur moyenne"} nameField={"pacAirEau.surface.averageHeight"} typeData={'number'} />
                </IonItem>

            </IonList>

            <IonList>
                <IonListHeader>
                    <IonLabel>Informations complémentaires</IonLabel>
                </IonListHeader>

                <MyCheckbox title={"Chape béton réalisé par le client"} nameField={'pacAirEau.complementaryInformation.screedMadeByTheCustomer.'} />
                <MyCheckbox title={"Maison étage"} nameField={'pacAirEau.complementaryInformation.multiStoreyHouse'} />

                <MyInputNumberThree title={'RDC'} nameFieldOne={'pacAirEau.complementaryInformation.rdc.volume'} nameFieldTwo={'pacAirEau.complementaryInformation.rdc.heatingType'}
                                    fieldOne={'Volume'} fieldTwo={'Type de chauffage'} />
                <MyInputNumberThree title={'ÉTAGE 1'} nameFieldOne={'pacAirEau.complementaryInformation.firstFloor.volume'} nameFieldTwo={'pacAirEau.complementaryInformation.firstFloor.heatingType'}
                                    fieldOne={'Volume'} fieldTwo={'Type de chauffage'} />
                <MyInputNumberThree title={'ÉTAGE 2'} nameFieldOne={'pacAirEau.complementaryInformation.secondFloor.volume'} nameFieldTwo={'pacAirEau.complementaryInformation.secondFloor.heatingType'}
                                    fieldOne={'Volume'} fieldTwo={'Type de chauffage'} />
                <IonList>
                    <IonListHeader>
                        <IonLabel>Température désirée</IonLabel>
                    </IonListHeader>
                    <MyInputSimple title={"RDC"} nameField={"pacAirEau.complementaryInformation.desiredTemperature.rdc"} typeData={'number'} />
                    <MyInputSimple title={"Étage 1"} nameField={"pacAirEau.complementaryInformation.desiredTemperature.firstFloor"} typeData={'number'} />
                    <MyInputSimple title={"Étage 2"} nameField={"pacAirEau.complementaryInformation.desiredTemperature.secondFloor"} typeData={'number'} />
                </IonList>

                <IonList>
                    <MyBoolean title={"Ensemble étage chauffé en permanence"} nameField={'pacAirEau.complementaryInformation.permanentlyHeatedFloor.value'} choiceOne={'Oui'} choiceTwo={'Non'} />
                    <MyInputSimple title={"Quand"} nameField={'pacAirEau.complementaryInformation.permanentlyHeatedFloor.when'} typeData={'string'} />
                    <MyInputSimple title={"Pourquoi"} nameField={'pacAirEau.complementaryInformation.permanentlyHeatedFloor.why'} typeData={'string'} />
                </IonList>

                <MyIonSelect title={'Type de radiateur'} nameField={'pacAirEau.complementaryInformation.radiatorType'} asArray={radiorTypeAsArray} frMap={radiorTypeFrMap} />

                <MyInputSimple title={"Température départ chaudière"} nameField={"pacAirEau.complementaryInformation.boilerStartTemperature"} typeData={'number'}  />

                <MyIonSelect title={"Énergie principal"} nameField={'pacAirEau.complementaryInformation.principalEnergy'} asArray={principalEnergyAsArray} frMap={principalEnergyFrMap} />

                <MyIonSelect title={"Énergie secondaire"} nameField={'pacAirEau.complementaryInformation.secondaryEnergy'} asArray={secondaryEnergyAsArray} frMap={secondaryEnergyFrMap} />

                <MyInputSimple title={'Consommation estimée principale/secondaire'} nameField={'pacAirEau.complementaryInformation.estimatedPrimaryAndSecondaryUse'} typeData={'number'} />

                {/*INFO CHAUDIERE */}

                <MyInputSimple title={'Diamètre tuyaux'} nameField={'pacAirEau.complementaryInformation.pipeDiameter'} typeData={'number'} />

            </IonList>

            <IonList>
                <MyCheckbox title={"ECS intégré à l'ancienne chaudière"} nameField={'pacAirEau.ecsIntegratedInTheOldBoiler'} />
            </IonList>

            {/*CONSOMMATION ESTIMEF*/}
        </IonContent>
    )
}

interface MyInputSimpleProps {
    title: string;
    nameField: string;
    typeData: any
};

interface MyBooleanProps {
    title: string;
    nameField: string;
};

interface MyInputNumberProps {
    title: string;
    nameFieldOne: string;
    nameFieldTwo: string;
    fieldOne: string;
    fieldTwo?: string;
};

interface MyIonSelectProps {
    title: string;
    nameField: string;
    asArray: any[];
    frMap: any
}

interface MyBooleanProp {
    title: string;
    nameField: string;
    choiceOne: string;
    choiceTwo: string;
};

function MyInputSimple({title, nameField, typeData}: MyInputSimpleProps) {
    return (
        <>
            <Controller
                name={nameField}
                render={
                    ({field}) => {
                        return (
                            <IonItem>
                                <IonLabel position={"stacked"}>{title}</IonLabel>
                                <IonInput value={field.value} onIonChange={field.onChange}  type={typeData} placeholder={title} />
                            </IonItem>
                        )
                    }
                }
            />
        </>
    )
}

function MyCheckbox({title, nameField}: MyBooleanProps) {
    return (
        <Controller
            name={nameField}
            //defaultValue={'<60'}
            render={
                ({ field: { value, onChange, } }) => {
                    return (
                        <IonItem>
                            <IonCheckbox
                                slot="start"
                                checked={value}
                                onIonChange={({ detail: { checked } }) => onChange(checked)}
                            ></IonCheckbox>
                            <IonLabel>{title}</IonLabel>
                        </IonItem>
                    )
                }
            }
        />
    )
}

function MyInputNumberThree({title, nameFieldOne, nameFieldTwo, fieldOne, fieldTwo}: MyInputNumberProps) {
    return (
        <>
            <IonListHeader>
                <IonLabel>{title}</IonLabel>
            </IonListHeader>

            <Controller
                name={nameFieldOne}
                render={
                    ({field}) => {
                        return (
                            <IonItem>
                                <IonLabel position={"stacked"}>{fieldOne}</IonLabel>
                                <IonInput value={field.value} onIonChange={field.onChange}  type={'number'} placeholder={fieldOne} />
                            </IonItem>
                        )
                    }
                }
            />

            <Controller
                name={nameFieldTwo}
                render={
                    ({field}) => {
                        return (
                            <IonItem>
                                <IonLabel position={"stacked"}>{fieldTwo}</IonLabel>
                                <IonInput value={field.value} onIonChange={field.onChange} placeholder={fieldTwo} />
                            </IonItem>
                        )
                    }
                }
            />
        </>
    )
}

function MyIonSelect({nameField, title, asArray, frMap}: MyIonSelectProps) {
    return(
        <>
            <Controller
                name={nameField}
                defaultValue={''}
                render={
                    ({ field: { value, onChange, } }) => {
                        return (
                            <IonItem>
                                <IonLabel>{title}</IonLabel>
                                <IonSelect placeholder={title} value={value} onIonChange={onChange}>
                                    {
                                        asArray.map(
                                            type => {
                                                const label = frMap[type];

                                                return <IonSelectOption key={`wallType-${type}`} value={type}>{label}</IonSelectOption>
                                            }
                                        )
                                    }
                                </IonSelect>
                            </IonItem>
                        )
                    }}
            />
        </>
    )
}

function MyBoolean({title, nameField, choiceOne, choiceTwo}: MyBooleanProp) {
    return(
        <>
            <IonListHeader>
                <IonLabel>{title}</IonLabel>
            </IonListHeader>

            <Controller
                name={nameField}
                defaultValue={''}
                render={
                    ({ field: { value, onChange, } }) => {
                        return (
                            <IonRadioGroup value={value} onIonChange={onChange}>
                                <IonItem>
                                    <IonLabel>{choiceOne}</IonLabel>
                                    <IonRadio slot="end" value={choiceOne}></IonRadio>
                                </IonItem>

                                <IonItem>
                                    <IonLabel>{choiceTwo}</IonLabel>
                                    <IonRadio slot="end" value={choiceTwo}></IonRadio>
                                </IonItem>
                            </IonRadioGroup>
                        )
                    }
                }
            />
        </>

    )
}

export default PacAirEau;
