import {IonCheckbox, IonContent, IonInput, IonItem, IonLabel, IonList} from "@ionic/react";
import React from "react";
import {Controller} from "react-hook-form";

const BallonThermodynamique: React.FC = () => {
    return(
        <IonContent>
            <IonList>
                <MyInputSimple title={"Pièce à installer"} nameField={'thermodynamicBalloonVt.partToBeInstalled'} typeData={'string'} />

                <MyInputSimple title={"Hauteur de la pièce"} nameField={'thermodynamicBalloonVt.roomHeight'} typeData={'number'} />

                <MyInputSimple title={"Superficie de la pièce"} nameField={'thermodynamicBalloonVt.roomArea'} typeData={'number'} />

                <MyCheckbox title={"Gaine"} nameField={'thermodynamicBalloonVt.gaine'}  />
            </IonList>
        </IonContent>
    )
}

interface MyInputSimpleProps {
    title: string;
    nameField: string;
    typeData: any
};

interface MyBooleanProps {
    title: string;
    nameField: string;
};

function MyInputSimple({title, nameField, typeData}: MyInputSimpleProps) {
    return (
        <>
            <Controller
                name={nameField}
                render={
                    ({field}) => {
                        return (
                            <IonItem>
                                <IonLabel position={"stacked"}>{title}</IonLabel>
                                <IonInput value={field.value} onIonChange={field.onChange}  type={typeData} placeholder={title} />
                            </IonItem>
                        )
                    }
                }
            />
        </>
    )
}

function MyCheckbox({title, nameField}: MyBooleanProps) {
    return (
        <Controller
            name={nameField}
            //defaultValue={'<60'}
            render={
                ({ field: { value, onChange, } }) => {
                    return (
                        <IonItem>
                            <IonCheckbox
                                slot="start"
                                checked={value}
                                onIonChange={({ detail: { checked } }) => onChange(checked)}
                            ></IonCheckbox>
                            <IonLabel>{title}</IonLabel>
                        </IonItem>
                    )
                }
            }
        />
    )
}

export default BallonThermodynamique;
