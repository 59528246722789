import {IonContent, IonIcon, IonItem, IonLabel, IonList, IonModal} from "@ionic/react";
import {
    businessOutline,
    businessSharp, checkmarkCircle,
    chevronDownOutline,
    chevronDownSharp, chevronForwardSharp
} from "ionicons/icons";
import {useActiveCompany, useActiveCompanyId, useAvailableCompanies, useSetActiveCompanyId} from "./company.store";
import {useRef} from "react";

export default function CompanySwitchButton() {
    const modal = useRef<HTMLIonModalElement>(null);
    const detail = useActiveCompany();
    const addr = [detail.zip, detail.town].filter(i => !!i).join(' - ');

    return (
        <>
            <IonItem id="company-modal" detail={false} lines={"none"} button>
                <IonIcon slot="start" md={businessSharp} ios={businessOutline} />
                <IonLabel>
                    <h2>{detail.label}</h2>
                    <p>{addr}</p>
                </IonLabel>
                <IonIcon slot="end" md={chevronDownSharp} ios={chevronDownOutline} />
            </IonItem>

            <IonModal ref={modal} trigger="company-modal" initialBreakpoint={0.25} breakpoints={[0, 0.25, 0.5, 0.75]}>
                <IonContent className="ion-padding">
                    <CompaniesList />
                </IonContent>
            </IonModal>
        </>
    )
}

function CompaniesList() {
    const activeCompanyId = useActiveCompanyId();
    const companies = useAvailableCompanies();
    const setActiveCompanyId = useSetActiveCompanyId();

    const handleSelectCompany = (id: string) => {
        setActiveCompanyId(id);

        // on ne se prend pas la tête on reload le tout à chaque fois q'une organisation est rendu active
        window.location.reload();
    }

    return (
        <IonList>
            {
                companies
                    .map(
                        (c, index) => {
                            const isActive = activeCompanyId === c.id;
                            const addr = [c.zip, c.town].filter(i => !!i).join(' - ');

                            return (
                                <IonItem
                                    style={{ '--background': 'white' }}
                                    key={`company-${index}`}
                                    lines={"full"}
                                    className={`ion-text-wrap ${isActive ? 'active' : ''}`}
                                    onClick={() => handleSelectCompany(c.id)}
                                    button
                                    detail={true}
                                    detailIcon={
                                        isActive ? checkmarkCircle : chevronForwardSharp
                                    }
                                >
                                    <IonIcon slot="start" md={businessSharp} ios={businessOutline} />
                                    <IonLabel>
                                        <h3>{c.label}</h3>
                                        <p>{addr}</p>
                                    </IonLabel>
                                </IonItem>
                            )
                        }
                    )
            }
        </IonList>
    )
}