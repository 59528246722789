import React from "react";
import {IonCol, IonContent, IonIcon, IonItem, IonLabel, IonList, IonRow, IonTextarea, IonThumbnail} from "@ionic/react";
import {Controller, useController} from "react-hook-form";
import {useSignaturePad} from "../SignaturePad/useSignaturePad";

const signatureIcon = '/assets/icon/signature.svg';

const Observations: React.FC = () => {
    return (
        <IonContent>
            <IonList>
                <Controller
                    name={"observations.technics"}
                    render={
                        ({ field: { value, onChange } }) => {
                            return (
                                <IonItem>
                                    <IonLabel position={"stacked"}>Techniques</IonLabel>
                                    <IonTextarea value={value} onIonChange={onChange} rows={3} placeholder="Techniques" />
                                </IonItem>
                            )
                        }
                    }
                />

                <Controller
                    name={"observations.financials"}
                    render={
                        ({ field: { value, onChange } }) => {
                            return (
                                <IonItem>
                                    <IonLabel position={"stacked"}>Financières</IonLabel>
                                    <IonTextarea value={value} onIonChange={onChange} rows={3} placeholder="Financières" />
                                </IonItem>
                            )
                        }
                    }
                />


                <Controller
                    name={"observations.others"}
                    render={
                        ({ field: { value, onChange } }) => {
                            return (
                                <IonItem>
                                    <IonLabel position={"stacked"}>Autres</IonLabel>
                                    <IonTextarea value={value} onIonChange={onChange} rows={3} placeholder="Autres" />
                                </IonItem>
                            )
                        }
                    }
                />
            </IonList>

            <IonRow>
                <SignatureClientCol />

                <SignatureTechnicienCol />
            </IonRow>
        </IonContent>
    )
}

export default Observations;

function SignatureClientCol() {
    const {
        field: { value: signature, onChange },
    } = useController({
        name: 'observations.customerSignature',
        defaultValue: "",
    });

    const persentSignaturePad = useSignaturePad({
        onDismiss(data, role) {
            if (role !== 'confirm') {
                return;
            }

            onChange(data);
        }
    });

    return (
        <IonCol className={"ion-no-padding"}>
            <IonItem button detail={true} onClick={() => persentSignaturePad()}>
                {
                    signature ? (
                        <IonThumbnail slot="start">
                            <img alt="Signature" src={signature} />
                        </IonThumbnail>
                    ) : (
                        <IonIcon icon={signatureIcon} slot="start"></IonIcon>
                    )
                }
                <IonLabel>
                    <h3>Signature client</h3>
                </IonLabel>
            </IonItem>
        </IonCol>
    )
}

function SignatureTechnicienCol() {
    const {
        field: { value: signature, onChange },
    } = useController({
        name: 'observations.technicianSignature',
        defaultValue: "",
    });

    const persentSignaturePad = useSignaturePad({
        onDismiss(data, role) {
            if (role !== 'confirm') {
                return;
            }

            onChange(data);
        }
    });

    return (
        <IonCol className={"ion-no-padding"}>
            <IonItem button detail={true} onClick={() => persentSignaturePad()}>
                {
                    signature ? (
                        <IonThumbnail slot="start">
                            <img alt="Signature" src={signature} />
                        </IonThumbnail>
                    ) : (
                        <IonIcon icon={signatureIcon} slot="start"></IonIcon>
                    )
                }
                <IonLabel>
                    <h3>Signature technicien</h3>
                </IonLabel>
            </IonItem>
        </IonCol>
    )
}
