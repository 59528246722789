import {ProductTypology} from "../../models/intervention.model";
import React from "react";
import PacAirEau from "./PacAirEau";
import PacAirAir from "./PacAirAir";
import BallonThermodynamique from "./BallonThermodynamique";
import Photovoltaique from "./Photovoltaique";
import {
    IonButton,
    IonCol,
    IonFooter,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    useIonLoading,
    useIonToast
} from "@ionic/react";
import InterventionReportNextButton from "../Interventions/InterventionReportNextButton";
import {useRapportVisiteTechniqueContext} from "./RapportVisiteTechniqueContext";
import {useFormContext} from "react-hook-form";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {editReport} from "../../calls/Interventions/editReport";
import Observations from "./Observations";
import Habitat from "./Habitat";
import {closeVisitetechnique} from "../../calls/Interventions/closeVisitetechnique";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import {useHistory} from "react-router-dom";

export default function ProductReport() {
    const { typologies, currentStep, prev, isLastStep } = useRapportVisiteTechniqueContext();

    const isFirstStep = currentStep === 0;

    const navs = useProductNav(typologies);

    const { label, component } = navs[currentStep];

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{label}</IonTitle>
                </IonToolbar>
            </IonHeader>

            {component}

            <IonFooter>
                <IonRow>
                    <IonCol>
                        {
                            !isFirstStep && (
                                <IonButton expand={"block"} fill="clear" onClick={prev}>Précédent</IonButton>
                            )
                        }
                    </IonCol>

                    <IonCol>
                        {
                            isLastStep ? (
                                <FinalizeButton />
                            ) : (
                                <InterventionReportNextButton />
                            )
                        }
                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonPage>
    )
}

function FinalizeButton() {
    const [present, dismiss] = useIonLoading();

    const [presentToast] = useIonToast();

    const { interventionId, onDismiss } = useRapportVisiteTechniqueContext();
    const { getValues } = useFormContext();
    const { mutateAsync, isLoading } = useMutation((report: any) => editReport(interventionId!, report));

    const { mutateAsync: closeVisiteTechnique, isLoading: closing } = useMutation(() => closeVisitetechnique(interventionId!));

    // Access the client
    const qc = useQueryClient();

    return (
        <IonLoadingButton
            loading={isLoading || closing}
            expand={"block"}
            onClick={async () => {
                present({
                    message: 'Visite technique en cours de clôture',
                    onDidDismiss() {
                        onDismiss!(); // Dismiss le modal

                        presentToast({
                            message: 'Visite technique clôturée avec succès',
                            position: 'bottom',
                            duration: 2000,
                        });
                    }
                });

                await mutateAsync(getValues());
                await closeVisiteTechnique();

                dismiss();

                // Invalidate and refetch
                await qc.invalidateQueries({ queryKey: ['intervention', interventionId] })
                await qc.invalidateQueries({ queryKey: ['intervention', interventionId, 'files'] });
            }}
        >Clôturer</IonLoadingButton>
    )
}

type NavDef = { label: string, component: React.ReactNode };

const observations: NavDef = {
    label: 'Observations',
    component: <Observations />,
}

const habitat: NavDef = {
    label: 'Habitat',
    component: <Habitat />,
}

const mapping: Record<ProductTypology, NavDef> = {
    PAC_AIR_EAU: {
        label: 'Pac Air / Eau',
        component: <PacAirEau />,
    },
    PAC_AIR_AIR: {
        label: 'Pac Air / Air',
        component: <PacAirAir />,
    },
    PHOTOVOLTAIC: {
        label: 'Photovoltaïque',
        component: <Photovoltaique />,
    },
    BALLON_THERMO: {
        label: 'Ballon thermodynamique',
        component: <BallonThermodynamique />,
    },
}

function useProductNav(typologies: ProductTypology[]) {
    return [
        habitat,
        ...(typologies as ProductTypology[]).map(typology => mapping[typology]),
        observations,
    ]
}
