import React, {useEffect, useRef, useState} from 'react';
import {GeoJSON, MapContainer, Marker, TileLayer, useMap, useMapEvent} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import {
    IonButton,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonProgressBar,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonLoading,
    useIonViewDidEnter
} from "@ionic/react";
import styled from "@emotion/styled";
import {
    Controller,
    FormProvider,
    useController,
    useForm,
    useFormContext,
    useFormState,
    useWatch
} from "react-hook-form";
import {
    checkmarkCircleOutline,
    checkmarkCircleSharp,
    chevronForwardOutline,
    chevronForwardSharp,
    locateOutline,
    locateSharp,
    searchOutline,
    searchSharp
} from "ionicons/icons";
import {getCodePostauxCommune} from "../../calls/Carto/getCodePostauxCommune";
import {useInfiniteQuery, useMutation, useQuery} from "@tanstack/react-query";
import IonLoadingButton from "../Common/Lab/IonLoadingButton";
import {chunk} from 'lodash';
import {Geolocation} from "@capacitor/geolocation";
import {getParcelleCommune} from "../../calls/Carto/getParcelleCommune";
import {createCadastreJob, CreateCadastreJobDto} from "../../calls/Cadastre/createCadastreJob";
import {FileToUploadDto, interventionAttachFiles} from "../../calls/Interventions/attachInterventionFiles";
import * as blobUtil from 'blob-util';
import {useActiveCompany} from "../Company/company.store";
import {fillCerfaDp, FillCerfaDpDto} from "../../calls/Cadastre/fillCerfaDp";
import {editVisitetechnique} from "../../calls/Interventions/editVisitetechnique";
import {getFeuilleCommune} from "../../calls/Carto/getFeuilleCommune";
import { useIsFetching } from '@tanstack/react-query'
import L from 'leaflet';

const steps: any = [
    <CodeInseeStep />,
    <MapStep />,
    <SummaryStep />
];

interface CadastreFormModel {
    commune: string;
    selectedSection: string;
    cadastre: {
        numero: string,
        feuille: number,
        section: string,
        code_dep: string,
        nom_com: string,
        code_com: string,
        com_abs: string,
        code_arr: string,
        idu: string,
        code_insee: string,
        contenance: number
    }
}

interface CadastreProps {
    interventionId: string,
    reference: string,
    customerName: string,
    chantier: {
        address: string,
        zipCode: string,
        town: string,
        coords: number[];
    }
    qtyPanneaux: number;
    onDismiss(base64: string | null, role: 'confirm' | 'cancel'): void;
}

function Cadastre({ onDismiss, ...restOfProps }: CadastreProps) {
    const { interventionId, reference, customerName, chantier, qtyPanneaux } = restOfProps;

    const {
        activeStep,
        isFirst,
        isLast,
        next,
        previous
    } = useSteps({
        intialStepIndex: 0,
        totalSteps: 3
    });

    const methods = useForm<CadastreFormModel>({
        defaultValues: {
            // commune: "64448#POEY DE LESCAR",
            // commune: "33281#MERIGNAC",
        }
    });

    return (
        <FormProvider {...methods}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Attacher une parcelle cadastrale</IonTitle>
                </IonToolbar>
                <ParcelleProgressBar />
            </IonHeader>

            <CadastreContext.Provider value={restOfProps}>
                <IonContent fullscreen>
                    {steps[activeStep]}
                </IonContent>
            </CadastreContext.Provider>

            <NavigationFooter
                interventionId={interventionId}
                reference={reference}
                customerName={customerName}
                activeStep={activeStep}
                isLast={isLast}
                chantier={chantier}
                qtyPanneaux={qtyPanneaux}
                onNext={() => next()}
                onPrevious={() => {}}
                onCancel={() => onDismiss(null, 'cancel')}
                onValidate={() => onDismiss(null, 'confirm')}
            />
        </FormProvider>
    )
}

export default Cadastre;

const CadastreContext = React.createContext<Partial<Omit<CadastreProps, 'onDismiss'>>>({});

const useCadastreContext = () => React.useContext(CadastreContext);

function ParcelleProgressBar() {
    const { getValues } = useFormContext<CadastreFormModel>();

    const { commune } = getValues();

    const selectedSection = useWatch({ name: 'selectedSection', defaultValue: '' });

    const parcelleLoading = useIsFetching({ queryKey: ['infinite', 'cadastre', commune, selectedSection, 'parcelles'] });

    if (!parcelleLoading) {
        return <></>;
    }

    return (
        <IonProgressBar type="indeterminate" />
    )
}

function useSteps({ intialStepIndex, totalSteps }: { intialStepIndex: number, totalSteps: number }) {
    const [activeStep, setActiveStep] = React.useState(intialStepIndex);

    const isFirst = activeStep === 0;
    const isLast = activeStep === (totalSteps - 1);

    const next = () => setActiveStep(activeStep < totalSteps ? activeStep + 1 : activeStep);
    const previous = () => setActiveStep(activeStep > 0 ? activeStep - 1 : activeStep);

    return {
        activeStep,
        isFirst,
        isLast,
        next,
        previous,
    }
}

interface NavigationFooterProps {
    interventionId: string;
    reference: string,
    customerName: string,
    activeStep: number;
    isLast: boolean;
    chantier: {
        address: string,
        zipCode: string,
        town: string
    }
    qtyPanneaux: number;
    onNext(): void;
    onPrevious(): void;
    onCancel(): void;
    onValidate(): void;
}

function NavigationFooter({ interventionId, reference, customerName, activeStep, onNext, onPrevious, onCancel, onValidate, isLast, chantier, qtyPanneaux }: NavigationFooterProps) {
    const company = useActiveCompany();
    const { handleSubmit } = useFormContext<CadastreFormModel>();
    const { isValid } = useFormState();

    const [present, dismiss] = useIonLoading();
    const [presentAlert] = useIonAlert();

    const { mutateAsync: submitAll, data: results, isLoading } = useMutation(async ({ cadastreJobDto, fillCerfaDto }: { cadastreJobDto: CreateCadastreJobDto, fillCerfaDto: FillCerfaDpDto }) => {
        // On rattache le cadastre à l'intervention
        await editVisitetechnique(interventionId, {
            cadastre: {
                commune: fillCerfaDto.commune,
                section: fillCerfaDto.section,
                parcelle: fillCerfaDto.parcelle,
                prefixe: fillCerfaDto.prefixe,
                contenance: fillCerfaDto.contenance.toString(),
            }
        });

        // On génère les documents cadastraux et on les attachent plus loin à l'intervention
        const {blob: cadastreBlob, filename: cadastreFilename} = await createCadastreJob(cadastreJobDto);
        const cadastreBase64Content = await blobUtil.blobToBase64String(cadastreBlob);

        // On rempli en auto le cerfa de demande préalable mairie
        const { blob: cerfaBlob, filename: cerfaFilename } = await fillCerfaDp(fillCerfaDto);
        const cerfaBase64Content = await blobUtil.blobToBase64String(cerfaBlob);

        // On join tous les fichiers générés
        const id = Date.now();

        const filesDto: FileToUploadDto[] = [
            {
                base64Content: cadastreBase64Content,
                filename: cadastreFilename,
                tag: 'Cadastre',
                description: `Cadastre VT ${company.name} ${customerName} ${reference} - ${id}`,
                contentType: 'application/pdf',
            },
            {
                base64Content: cerfaBase64Content,
                filename: cerfaFilename,
                tag: 'CERFA_13703_09',
                description: `CERFA 13703_09 ${company.name} ${customerName} ${reference} - ${id}`,
                contentType: 'application/pdf',
            }
        ];

        await interventionAttachFiles(interventionId, filesDto);
    });

    const onSubmit = async ({ cadastre, commune }: CadastreFormModel) => {
        const [codeInsee, libelleAcheminement] = commune.split('#');

        try {
            present({
                message: 'Vos documents sont en cours de génération, veuillez patienter, cette opération peut durer quelques minutes',
            });

            const parsed = chantier.address.split(/(\d+)/g);

            await submitAll({
                cadastreJobDto: {
                    commune: libelleAcheminement,
                    section: cadastre.section,
                    parcelle: cadastre.numero,
                    prefixe: cadastre.com_abs,
                    codePostal: chantier.zipCode,
                },
                fillCerfaDto: {
                    commune: libelleAcheminement,
                    section: cadastre.section,
                    parcelle: cadastre.numero,
                    prefixe: cadastre.com_abs,
                    contenance: cadastre.contenance,
                    chantier: {
                        street: parsed[1] || '',
                        address: parsed[2] || '',
                        town: chantier.town,
                        postalCode: chantier.zipCode
                    },
                    qtyPanneaux,
                }
            });

            dismiss();
            onValidate();
        } catch (e) {
            console.error(e);
            dismiss();
            presentAlert({
                header: 'Erreur',
                message: 'Une erreur est survenue lors de la génération des documents',
                buttons: ['OK'],
            })
        }
    };

    return (
        <IonFooter>
            <IonRow>
                <IonCol>
                    <IonButton expand={"block"} fill="clear" onClick={() => onCancel()}>
                        Annuler
                    </IonButton>
                </IonCol>

                {/*<IonCol>*/}
                {/*        <IonButton onClick={async () => {*/}
                {/*            try {*/}
                {/*                const coordinates = await Geolocation.getCurrentPosition();*/}
                {/*                const latLng = [*/}
                {/*                    coordinates.coords.latitude || 0,*/}
                {/*                    coordinates.coords.longitude || 0*/}
                {/*                ];*/}

                {/*                if (map) {*/}
                {/*                    (map as any).flyTo(latLng);*/}
                {/*                }*/}
                {/*            } catch (e) {*/}
                {/*                console.error(e);*/}
                {/*            }*/}
                {/*        }}>*/}
                {/*            <IonIcon slot={'start'} md={locateSharp} ios={locateOutline} />*/}
                {/*            Centrer*/}
                {/*        </IonButton>*/}
                {/*</IonCol>*/}

                <IonCol>
                    {
                        !isLast ?                             (
                            <IonButton expand={"block"} onClick={() => onNext()} disabled={!isValid}>
                                Suivant
                                <IonIcon slot={'end'} md={chevronForwardSharp} ios={chevronForwardOutline} />
                            </IonButton>
                        ) : (
                            (
                                <IonLoadingButton loading={isLoading} expand={"block"} onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                                    <IonIcon slot={'start'} md={checkmarkCircleSharp} ios={checkmarkCircleOutline} />
                                    Valider
                                </IonLoadingButton>
                            )
                        )
                    }
                </IonCol>
            </IonRow>
        </IonFooter>
    )
}

function CodeInseeStep() {
    const { chantier } = useCadastreContext();

    const [cp, setCp] = useState<string>(chantier?.zipCode || '');
    const { mutate, data: results, isLoading } = useMutation((cp: string) => getCodePostauxCommune(cp));

    useEffect(() => {
        if (!cp) {
            return;
        }

        mutate(cp);
    }, []);

    return (
        <div className={'ion-padding'}>
            <IonItem>
                <IonLabel position="stacked">Code postal</IonLabel>
                <IonInput type={"number"} placeholder="Veuillez saisir le CP ici" value={cp} onIonChange={e => setCp(e.detail?.value || '')} />
                <IonLoadingButton loading={isLoading} onClick={() => mutate(cp)} slot="end">
                    <IonIcon md={searchSharp} ios={searchOutline} slot="start" />
                    Rechercher
                </IonLoadingButton>
            </IonItem>

            <Controller
                name={'commune'}
                rules={{
                    required: 'Code postal obligatoire',
                }}
                render={({field: { value, onChange} }) => {
                    return (
                        <IonList style={{ maxHeight: '40vh', overflow: 'scroll' }}>
                            <IonListHeader>
                                <IonLabel>Communes ({ results?.length || 0 })</IonLabel>
                            </IonListHeader>
                            <IonRadioGroup value={value} onIonChange={({ detail: { value } }) => onChange(value)}>
                                {
                                    (results || []).map(
                                        res => (
                                            <IonItem key={res.libelleAcheminement}>
                                                <IonRadio slot="start" value={`${res.codeCommune}#${res.libelleAcheminement}`} />
                                                <IonLabel>{res.nomCommune} ({res.codeCommune})</IonLabel>
                                            </IonItem>
                                        )
                                    )
                                }
                            </IonRadioGroup>
                        </IonList>
                    )
                }}
            />
        </div>
    )
}

const StyledMapContainer = styled(MapContainer)`
    height: 100%;
    width: 100%;
`;

function useFeuilleQuery() {
    const { getValues } = useFormContext<CadastreFormModel>();

    const { commune } = getValues();

    const [codeInsee] = commune.split('#');

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: ['infinite', 'cadastre', commune, 'feuilles'],
        queryFn: ({ queryKey, pageParam = { start: 0, limit: 1000 } }) =>{
            return getFeuilleCommune({ codeInsee, start: pageParam.start, limit: pageParam.limit });
        },
        getNextPageParam: (lastPage, pages) => {
            const { pagination: { start, limit }, data: { numberReturned, totalFeatures } } = lastPage;

            const nextStart = start + limit;

            return (numberReturned < limit) ? undefined : { start: nextStart, limit };
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    const isLoading = status === 'loading' || isFetchingNextPage || isFetching;

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (hasNextPage) {
            fetchNextPage();
        }
    }, [isLoading, hasNextPage]);

    return {
        data,
        error,
        status,
        isFetching,
        isFetchingNextPage,
    }
}

function useParcellesQuery() {
    const { getValues } = useFormContext<CadastreFormModel>();

    const { commune } = getValues();

    const [codeInsee] = commune.split('#');

    const selectedSection = useWatch({ name: 'selectedSection', defaultValue: '' });

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: ['infinite', 'cadastre', commune, selectedSection, 'parcelles'],
        queryFn: ({ queryKey, pageParam = { start: 0, limit: 1000 } }) => {
            return getParcelleCommune({ codeInsee, section: selectedSection, start: pageParam.start, limit: pageParam.limit });
        },
        getNextPageParam: (lastPage, pages) => {
            const { pagination: { start, limit }, data: { numberReturned, totalFeatures } } = lastPage;

            const nextStart = start + limit;

            return (numberReturned < limit) ? undefined : { start: nextStart, limit };
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        enabled: !!selectedSection,
    });

    const isLoading = status === 'loading' || isFetchingNextPage || isFetching;

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (hasNextPage) {
            fetchNextPage();
        }
    }, [isLoading, hasNextPage]);

    return {
        data,
        error,
        status,
        isFetching,
        isFetchingNextPage,
    }
}

const defaultGeojsonStyle = {
    bubblingMouseEvents: true,
    color: "#38f",
    fill: true,
    fillcolor: null,
    fillopacity: 0.2,
    filtrule: "evenodd",
    interactive: true,
    lineCap: "round",
    lineJoin: "round",
    noClip: false,
    opacity: 1,
    pane: "overlayPane",
    smoothFactor: 1,
    stroke: true,
    weight: 3,
}

const clickedGeoJsonStyle = {
    ...defaultGeojsonStyle,
    color: "#FFBF00",
}

const geoJsonStyle: any = {
    default: {
        bubblingMouseEvents: true,
        color: "#38f",
        fill: true,
        fillcolor: null,
        fillopacity: 0.2,
        filtrule: "evenodd",
        interactive: true,
        lineCap: "round",
        lineJoin: "round",
        noClip: false,
        opacity: 1,
        pane: "overlayPane",
        smoothFactor: 1,
        stroke: true,
        weight: 3,
    },
    clicked: {
        bubblingMouseEvents: true,
        color: "#FFBF00",
        fill: true,
        fillcolor: null,
        fillopacity: 0.2,
        filtrule: "evenodd",
        interactive: true,
        lineCap: "round",
        lineJoin: "round",
        noClip: false,
        opacity: 1,
        pane: "overlayPane",
        smoothFactor: 1,
        stroke: true,
        weight: 3,
    },
    feuilleDefault: {
        ...defaultGeojsonStyle,
        color: '#7b7b7b',
    },
}

function MapStep() {
    const { chantier } = useCadastreContext();

    // Chargement des feuilles ici et ré-utilisation de ce hook en interne dans GeoJSONFeuilles
    const { status, error, isFetching, isFetchingNextPage } = useFeuilleQuery();

    const isLoading = status === 'loading';
    const isErrored = status === 'error';

    const [map, setMap] = useState(null);

    if (isLoading || isFetchingNextPage || isFetching) {
        return (
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <div className="ion-text-center">
                            <h3>Chargement des données cadastrales de la commune en cours, veuillez de patienter</h3>
                            <IonProgressBar type="indeterminate"></IonProgressBar>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        )
    }

    if (isErrored) {
        return (
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <div className="ion-text-center">
                            <h3>Une erreur est survenue lors de la récupération des données cadastrales</h3>
                            <p>{(error as any)?.message || 'Erreur inconnue'}</p>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        )
    }

    const [lng, lat] = chantier?.coords || [0, 0];
    const position: [number, number] = [lat, lng];

    const newicon = new L.Icon({
        iconUrl: "/marker-icon-cadastre.png",
        iconSize: [45, 45]
    })

    return (
        <StyledMapContainer ref={setMap as any} zoom={13} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy'
                url={"https://tile2.france-cadastre.fr/styles/klokantech-basic/{z}/{x}/{y}.png"}
            />

            <Marker position={position} icon={newicon} />
            
            <GeoJSONFeuilles />
            <GeoJSONParcelles />

            <ShowLabelOnZoom />
            <SelectedParcelle />
        </StyledMapContainer>
    )
}

function GeoJSONFeuilles() {
    const map = useMap();

    const { data } = useFeuilleQuery();
    const features = (data?.pages || []).map(p => p.data);

    // Le champ du form à compléter dans cette étape
    const {
        field,
    } = useController({
        name: 'selectedSection',
        rules: { required: true },
    });

    useEffect(() => {
        //
        const firstPage = (data?.pages || [])[0];
        const bounds: any = chunk(firstPage ? firstPage.data.bbox : [0, 0, 0, 0], 2).map(chunk => chunk.reverse());
        // const features = (data?.pages || []).map(p => p.data);
        map.fitBounds(bounds);
    }, []);

    return (
        <>
            {
                features.map(
                    (f, index) => (
                        <FeuilleGeoJSON
                            key={`feature-feuille-${index}`}
                            feature={f}
                            onClick={(selectedSection) => field.onChange(selectedSection)}
                        />
                    )
                )
            }
        </>
    )
}

function FeuilleGeoJSON({ feature, onClick }: { feature: any, onClick: (cadastreProperties: any) => void }) {
    const geojsonRef = useRef(null);

    const map = useMap();

    return (
        <GeoJSON
            ref={geojsonRef as any}
            style={geoJsonStyle.feuilleDefault}
            data={feature}
            onEachFeature={(feature, layer) => {
                const {section, numero, contenance} = (layer as any).feature.properties;

                const popup = `<strong>Section</strong> ${section}`;

                layer.bindPopup(popup);
                // layer.bindTooltip(popup, {permanent: true, direction:"center"});
            }}
            eventHandlers={{
                click: (e) => {
                    (geojsonRef.current as any).setStyle(geoJsonStyle.feuilleDefault);
                    e.layer.setStyle(geoJsonStyle.clicked);
                    e.layer.bringToFront();

                    const { section } = (e.layer as any).feature.properties;

                    map.setView(e.latlng, 16);

                    onClick(section);
                },
            }}
        />
    )
}

function GeoJSONParcelles() {
    const { data } = useParcellesQuery();

    const features = (data?.pages || []).map(p => p.data);

    // Le champ du form à compléter dans cette étape
    const {
        field,
    } = useController({
        name: 'cadastre',
        rules: { required: true },
    });

    return (
        <>
            {
                features.map(
                    (f, index) => (
                        <ParcelleGeoJson
                            key={`feature-cadastre-${index}`}
                            feature={f}
                            onClick={(cadastreProperties) => field.onChange(cadastreProperties)}
                        />
                    )
                )
            }
        </>
    )
}

function ParcelleGeoJson({ feature, onClick }: { feature: any, onClick: (cadastreProperties: any) => void }) {
    const geojsonRef = useRef(null);

    return (
        <GeoJSON
            ref={geojsonRef as any}
            style={geoJsonStyle.clicked}
            data={feature}
            onEachFeature={(feature, layer) => {
                const {section, numero, contenance} = (layer as any).feature.properties;

                const popup = `<strong>Section</strong> ${section} <strong>Parcelle</strong> ${+numero} <strong>Superficie</strong> ${+contenance} m2`;

                layer.bindPopup(popup);
                // layer.bindTooltip(popup, {permanent: true, direction:"center"});
            }}
            eventHandlers={{
                click: (e) => {
                    // (geojsonRef.current as any).setStyle(geoJsonStyle.default);
                    // e.layer.setStyle(geoJsonStyle.clicked);
                    e.layer.bringToFront();

                    const props = (e.layer as any).feature.properties;
                    onClick(props);
                },
            }}
        />
    )
}

function ShowLabelOnZoom() {
    const map = useMap();

    const onZoom = (e: any) => {

    }

    useMapEvent('zoom', onZoom);

    return (
        <div className={'leaflet-top leaflet-right'}>
            <div className="leaflet-control leaflet-bar" style={{ border: 'none' }}>
                <IonButton onClick={async () => {
                    try {
                        const coordinates = await Geolocation.getCurrentPosition();
                        const latLng = [
                            coordinates.coords.latitude || 0,
                            coordinates.coords.longitude || 0
                        ];

                        if (map) {
                            (map as any).flyTo(latLng);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }}>
                    <IonIcon slot={'start'} md={locateSharp} ios={locateOutline} />
                    Centrer
                </IonButton>
            </div>
        </div>
    )
}

function SelectedParcelle() {
    const cadastre = useWatch({ name: 'cadastre', defaultValue: '' });

    if (cadastre) {
        return (
            <div className={'leaflet-bottom leaflet-left'}>
                <div className="leaflet-control leaflet-bar" style={{ border: 'none', background: 'white', padding: 5 }}>
                    <h5 className={'ion-no-margin'}>
                        <strong>Section</strong> {cadastre.section} <strong>Parcelle</strong> {cadastre.numero} <strong>Superficie</strong> {cadastre.contenance} m2
                        &nbsp;<IonIcon color={'success'} md={checkmarkCircleSharp} ios={checkmarkCircleSharp} />
                    </h5>
                </div>
            </div>
        )
    }

    return null;
}

function SummaryStep() {
    const { getValues } = useFormContext<CadastreFormModel>();

    const data = getValues();

    const values = [
        { label: 'Superficie', value: `${data.cadastre.contenance} m2` },
        { label: 'Section de parcelle', value: data.cadastre.section },
        { label: 'Numéro de parcelle', value: data.cadastre.numero },
        { label: 'Préfixe', value: data.cadastre.com_abs },
    ];

    return (
        <IonList>
            <IonListHeader>
                <IonLabel>Parcelle {data.cadastre.section} {data.cadastre.numero}</IonLabel>
            </IonListHeader>
            {
                values.map(
                    ({ label, value }) => (
                        <IonItem key={label}>
                            <IonLabel>
                                <h3>{label}</h3>
                                <p>{value}</p>
                            </IonLabel>
                        </IonItem>
                    )
                )
            }
        </IonList>
    )
}

function useResizeMap(map: any) {
    useIonViewDidEnter(() => {
        window.dispatchEvent(new Event("resize"));
    });

    useEffect(() => {
        if (!map) {
            return;
        }

        window.dispatchEvent(new Event("resize"));

        setTimeout(function () {
            (map as any).invalidateSize();
        },1);
    }, [map]);
}

function normalizeBbox(bbox: any) {
    return bbox ? chunk(bbox, 2).map(chunk => chunk.reverse()) : undefined;
}
